import React from 'react'
import { Stack } from 'react-bootstrap';
import {
    BsFill1CircleFill,
    BsFill2CircleFill,
    BsFill3CircleFill,
    BsFill4CircleFill,
    BsFill5CircleFill,
    BsFill6CircleFill,
} from 'react-icons/bs';
import { TbArrowRight } from "react-icons/tb";
import configMscw from '../../config/mscw.json';
import configCashkrub from '../../config/cashkrub.json';

const Stepper = ({ activeStep }) => {
    const brand = localStorage.getItem('brand')
    const configSource = brand === 'CASHKRUB' ? configCashkrub.step_progress.icon_color : configMscw.step_progress.icon_color;

    return (
        <Stack className="mt-4 mb-4 center" style={{ display: 'flex', justifyContent: 'space-between' }} direction="horizontal">
            <BsFill1CircleFill fontSize={'24px'} color={activeStep.current > 0 ? configSource : '#fff'} />
            <TbArrowRight fontSize={'24px'} color={activeStep.current > 1 ? configSource : '#fff'} />
            <BsFill2CircleFill fontSize={'24px'} color={activeStep.current > 1 ? configSource : '#fff'} />
            <TbArrowRight fontSize={'24px'} color={activeStep.current > 2 ? configSource : '#fff'} />
            <BsFill3CircleFill fontSize={'24px'} color={activeStep.current > 2 ? configSource : '#fff'} />
            <TbArrowRight fontSize={'24px'} color={activeStep.current > 3 ? configSource : '#fff'} />
            <BsFill4CircleFill fontSize={'24px'} color={activeStep.current > 3 ? configSource : '#fff'} />
            <TbArrowRight fontSize={'24px'} color={activeStep.current > 4 ? configSource : '#fff'} />
            <BsFill5CircleFill fontSize={'24px'} color={activeStep.current > 4 ? configSource : '#fff'} />
            <TbArrowRight fontSize={'24px'} color={activeStep.current > 5 ? configSource : '#fff'} />
            <BsFill6CircleFill fontSize={'24px'} color={activeStep.current > 5 ? configSource : '#fff'} />
        </Stack>
    )
}

export default Stepper
