import React from 'react'
import { Modal } from 'react-bootstrap'
import CloseButton from 'react-bootstrap/CloseButton';
import LogoPico from './../../assets/images/Logo-Pico-เงินด่วน.png';
// import LogoCashkrub from './../../assets/images/CASH-KRUB-LOGO-02-white.png';

const Model2 = ({ show, handleClose }) => {
    // const brand = localStorage.getItem("brand");
    // const logoSource = brand === 'CASHKRUB' ? LogoCashkrub : LogoMscw;

    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Body>
                    <div className="right">
                        <CloseButton style={{ backgroundColor: '#fff', padding: '10px', marginBottom: '20px', fontSize: '12px' }} onClick={handleClose} />
                    </div>
                    <div className="center mb-4" >
                        <p style={{ fontSize: '16px', textDecoration: 'underline' }}>การผ่อนชำระเงินกู้</p>
                        <img src={LogoPico} className="logo center" alt="" />
                    </div>
                    <p style={{ fontSize: '12px' }}>
                        ข้าพเจ้าขอรับรองว่าข้อความข้างต้นเป็นความจริงทุกประการรวมทั้งได้รับทราบเงื่อนไข
                        หลักเกณฑ์ที่กำหนดในการใช้บริการสินเชื่อและโดยการลงนามในใบสมัคร (สินเชื่อบุคคล) นี้ เมื่อบริษัทฯ ได้อนุมัติเงินกู้ให้ข้าพเจ้าแล้วข้าพเจ้าตกลงปฏิบัติตามภาระผูกพันที่กิดขึ้นตามสัญญากู้เงินที่ปรากฎอยู่ในใบสมัคร รวมทั้งข้อกำหนด และเงื่อนไขใดๆ เพิ่มเติมที่บริษัทฯ จะแจ้งให้ทราบในคราวต่อๆ ไป และให้ถือว่าใบสมัครสินเชื่อบุคคลนี้เป็นส่วนหนึ่งของสัญญากู้เงินสินเชื่อบุคคล) ด้วย<br />
                        <br />
                    </p>
                    <div style={{ border: '1px solid white', padding: '10px', borderRadius: '10px', marginBottom: '5rem' }}>
                        <p style={{ fontSize: '12px' }}>
                            ข้าพเจ้าได้อ่าน และเข้าใจข้อกำหนดเงื่อนไขต่างๆ ที่เกี่ยวข้องอย่างถี่ถ้วนแล้วพร้อมทั้งได้รับสำเนาสัญญากู้เงิน สินเชื่อบุคคล) ไว้เรียบร้อยแล้ว
                            จึงลงลายมือชื่อไว้เป็นหลักฐานและข้อมูล และเอกสารที่ได้นำมาใช้ประกอบการขอสินเชื่อนั้น
                            เป็นเอกสารที่ถูกต้องหากข้อมูลเอกสารหรือคำรับรองใดๆ ของข้าพเจ้าที่ให้ไว้แก่การให้สินเชื่อ
                            ดังกล่าวไม่ถูกต้องหรือมีการพิสูจน์ว่าไม่ตรง
                            ความเป็นจริงหรือเข้าใจผิดในสาระสำคัญแล้ว
                            บริษัทฯ มีสิทธิระงับการให้สินเชื่อ หรือบอกเลิก
                            และให้ชำระหนี้เงินต้น ดอกเบี้ย ค่าธรรมเนียม ค่าปรับและจำนวนเงินอื่นใดที่ผู้กู้จะต้องชำระให้
                            บริษัทฯ ภายใต้สัญญากู้เงินฉบับนี้
                        </p>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Model2