import React, { useEffect } from 'react'

import { Col, Row, Button, Form } from 'react-bootstrap';

// import { validCompPhone } from '../../../src/assets/regex';
import Address from './address';
import Career from './career';

const FormEditor3 = ({ isError, setIsError, values, setValues, setActiveStep, validateForm }) => {

    console.log(values)

    useEffect(() => {
        if (values.radioGroupAddress2 === "1") {
            setValues((prevState) => ({
                ...prevState,
                compAddress: values.address,
                compProvince: values.province,
                compCounty: values.county,
                compDistrict: values.district,
                compZipcode: values.zipcode,
            }));
        } else if (values.radioGroupAddress2 === "2") {
            setValues((prevState) => ({
                ...prevState,
                compAddress: values.currentAddress,
                compProvince: values.currentProvince,
                compCounty: values.currentCounty,
                compDistrict: values.currentDistrict,
                compZipcode: values.currentZipcode,
            }));
        }
    }, [setValues,
        values.address,
        values.county,
        values.currentAddress,
        values.currentCounty,
        values.currentDistrict,
        values.currentProvince,
        values.currentZipcode,
        values.district,
        values.province,
        values.radioGroupAddress2,
        values.zipcode
    ])

    const handleSubmit = (event) => {
        event.preventDefault();

        const hasErrorValue = validateForm()
        if (hasErrorValue) return

        setActiveStep((prevActiveStep) => ({ current: prevActiveStep.current + 1, top: 4 }));
    };


    const handleChange = (event) => {
        const { name, value } = event.target

        setValues((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    }

    return (
        <div>
            <Form onSubmit={handleSubmit}>
                <Form.Group>
                    <Row>
                        <Col xs="12" style={{ padding: 5, marginTop: '-0.5rem' }}>
                            <Form.Check className='custom-radio-dot'>
                                <Form.Check.Input
                                    id="radioGroupAddress2-0"
                                    type="radio"
                                    name="radioGroupAddress2"
                                    value="0"
                                    checked={values.radioGroupAddress2 === '0'}
                                    onChange={() =>
                                        setValues((prevState) => ({
                                            ...prevState,
                                            radioGroupAddress2: "0",
                                            compAddress: "",
                                            compProvince: "",
                                            compCounty: "",
                                            compDistrict: "",
                                            compZipcode: "",
                                        }))
                                    }
                                    style={{
                                        width: '20px',
                                        height: '20px',
                                        borderRadius: '50px'
                                    }} />
                                <Form.Check.Label style={{
                                    marginLeft: '5px',
                                }}>
                                    <p style={{ textDecoration: 'none', fontWeight: 300 }}>
                                        กำหนดเอง
                                    </p>
                                </Form.Check.Label>
                            </Form.Check>
                        </Col>

                        <Col xs="12" style={{ padding: 5, marginTop: '-0.5rem' }}>
                            <Form.Check className='custom-radio-dot'>
                                <Form.Check.Input
                                    id="radioGroupAddress2-1"
                                    type="radio"
                                    name="radioGroupAddress2"
                                    value="1"
                                    checked={values.radioGroupAddress2 === '1'}
                                    onChange={() =>
                                        setValues((prevState) => ({
                                            ...prevState,
                                            radioGroupAddress2: '1',
                                            compAddress: values.address,
                                            compProvince: values.province,
                                            compCounty: values.county,
                                            compDistrict: values.district,
                                            compZipcode: values.zipcode,
                                        }))
                                    }
                                    style={{
                                        width: '20px',
                                        height: '20px',
                                        borderRadius: '50px'
                                    }} />
                                <Form.Check.Label style={{
                                    marginLeft: '5px',
                                }}>
                                    <p style={{ textDecoration: 'none', fontWeight: 300 }}>
                                        ใช้ที่อยู่บัตรประชาชน
                                    </p>
                                </Form.Check.Label>
                            </Form.Check>
                        </Col>

                        <Col xs="12" style={{ padding: 5, marginTop: '-0.5rem' }}>
                            <Form.Check className='custom-radio-dot'>
                                <Form.Check.Input
                                    id="radioGroupAddress2-2"
                                    type="radio"
                                    name="radioGroupAddress2"
                                    value="2"
                                    checked={values.radioGroupAddress2 === '2'}
                                    onChange={() =>
                                        setValues((prevState) => ({
                                            ...prevState,
                                            radioGroupAddress2: '2',
                                            compAddress: values.currentAddress,
                                            compProvince: values.currentProvince,
                                            compCounty: values.currentCounty,
                                            compDistrict: values.currentDistrict,
                                            compZipcode: values.currentZipcode,
                                        }))
                                    }
                                    style={{
                                        width: '20px',
                                        height: '20px',
                                        borderRadius: '50px'
                                    }} />
                                <Form.Check.Label style={{
                                    marginLeft: '5px',
                                }}>
                                    <p style={{ textDecoration: 'none', fontWeight: 300 }}>
                                        ใช้ที่อยู่ปัจจุบัน
                                    </p>
                                </Form.Check.Label>
                            </Form.Check>
                        </Col>
                    </Row>

                    <br />
                    <p style={{ fontWeight: 300, fontSize: 16, textAlign: 'left' }}>ที่อยู่ที่ทำงาน</p>
                    <Row>
                        <Col xs="12" style={{ padding: 5 }}>
                            <Form.Group className="mb-2">
                                <Form.Control
                                    size="md"
                                    placeholder="ประเภทกิจการบริษัท/โรงงาน"
                                    type="text"
                                    name="compType"
                                    id="compType"
                                    value={values.compType}
                                    onChange={handleChange}
                                    isInvalid={isError.err?.compType}
                                    autoComplete="off" // แก้ไขปัญหา autocomplete

                                />
                                <Form.Control.Feedback type="invalid" >
                                    โปรดกรอกประเภทกิจการบริษัท/โรงงาน
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col xs="12" style={{ padding: 5 }}>
                            <Form.Group className="mb-2">
                                <Form.Control
                                    size="md"
                                    placeholder="ชื่อบริษัท/โรงงาน"
                                    type="text"
                                    name="compName"
                                    id="compName"
                                    value={values.compName}
                                    onChange={handleChange}
                                    isInvalid={isError.err?.compName}
                                    autoComplete="off" // แก้ไขปัญหา autocomplete
                                />
                                <Form.Control.Feedback type="invalid" >
                                    โปรดกรอกชื่อบริษัท/โรงงาน
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Address
                            values={{
                                address: values.compAddress,
                                province: values.compProvince,
                                county: values.compCounty,
                                district: values.compDistrict,
                                zipcode: values.compZipcode
                            }}
                            name={{
                                address: 'compAddress',
                                province: 'compProvince',
                                county: 'compCounty',
                                district: 'compDistrict',
                                zipcode: 'compZipcode'
                            }}
                            handleChange={handleChange}
                            isError={isError}
                            disabled={values.radioGroupAddress2 === '0' ? false : true}
                        />
                    </Row>

                    <p style={{ fontWeight: 300, fontSize: 16 }}>ข้อมูลที่ทำงาน</p>
                    <Career
                        values={{
                            careerType: values.careerType,
                            career: values.career
                        }}
                        name={{
                            careerType: 'careerType',
                            career: 'career'
                        }}
                        setValues={setValues}
                        isError={isError}
                        disabled={values.radioGroupAddress2 === '0' ? false : true}
                    />
                    <Row>
                        <Col xs="12" style={{ padding: 5 }}>
                            <Form.Group className="mb-2">
                                <Form.Control
                                    size="md"
                                    placeholder="ตำแหน่ง"
                                    type="text"
                                    name="compPosition"
                                    value={values.compPosition}
                                    onChange={handleChange}
                                    isInvalid={isError.err?.compPosition}
                                />
                                <Form.Control.Feedback type="invalid" >
                                    โปรดกรอกตำแหน่ง
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col xs="6" style={{ padding: 5 }}>
                            <Form.Group className="mb-2">
                                <Form.Control
                                    size="md"
                                    placeholder="อีเมล"
                                    type="email"
                                    name="compEmail"
                                    value={values.compEmail}
                                    onChange={handleChange}
                                    isInvalid={isError.err?.compEmail}
                                />
                                <Form.Control.Feedback type="invalid" >
                                    โปรดกรอกอีเมล
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col xs="6" style={{ padding: 5 }}>
                            <Form.Group className="mb-2">
                                <Form.Control
                                    size="md"
                                    placeholder="เบอร์โทรที่ทำงาน"
                                    type="tel"
                                    name="compPhone"
                                    value={values.compPhone}
                                    onChange={handleChange}
                                    isInvalid={isError.err?.compPhone}
                                />
                                <Form.Control.Feedback type="invalid" >
                                    โปรดกรอกเบอร์โทรที่ทำงาน
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col xs="12" style={{ padding: 5 }}>
                            <Form.Group className="mb-2">
                                <Form.Control
                                    size="md"
                                    placeholder="รายได้ (ประจำ)"
                                    type="number"
                                    name="mainIncome"
                                    value={values.mainIncome}
                                    onChange={handleChange}
                                    isInvalid={isError.err?.mainIncome}
                                />
                                <Form.Control.Feedback type="invalid" >
                                    โปรดกรอกรายได้ (ประจำ)
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col xs="12" style={{ padding: 5 }}>
                            <Form.Group className="mb-2">
                                <Form.Control
                                    size="md"
                                    placeholder="รายได้ (พิเศษ)"
                                    type="number"
                                    name="extraIncome"
                                    value={values.extraIncome}
                                    onChange={handleChange}
                                    isInvalid={isError.err?.extraIncome}
                                />
                                <Form.Control.Feedback type="invalid" >
                                    โปรดกรอกรายได้ (พิเศษ)
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12" style={{ padding: 5 }}>
                            <Form.Group className="mb-2">
                                <Form.Control
                                    size="md"
                                    placeholder="แหล่งที่มารายได้"
                                    type="text"
                                    name="incomeSource"
                                    value={values.incomeSource}
                                    onChange={handleChange}
                                    isInvalid={isError.err?.incomeSource}
                                />
                                <Form.Control.Feedback type="invalid" >
                                    โปรดกรอกแหล่งที่มารายได้
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    <div className="mt-5">
                        <Row>
                            <Button variant="secondary" type="submit" style={{ borderRadius: '10px' }}>ต่อไป</Button>
                            <Button variant="danger" style={{ borderRadius: '10px', marginTop: '20px', display: values.haveData ? '' : 'none' }} onClick={() => window.history.back()}>ยกเลิกแก้ไขข้อมูล</Button>
                        </Row>
                    </div>
                </Form.Group>
            </Form>
        </div>
    )
}

export default FormEditor3
