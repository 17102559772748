import React from "react";
import './App.css';
import { useEffect } from 'react';
import { useLiff } from 'react-liff';
import { Button } from "react-bootstrap";
import * as userHttpServices from './services/digital-app-services';
import Cookies from 'js-cookie';
import LogoPico from './assets/images/Logo-Pico-เงินด่วน.png';

function App() {
  const { error, liff, isLoggedIn, ready } = useLiff();

  const controller = (type) => {
    const Map = {
      '01': '/home',
      '02': '/prescreen',
      '03': '/upload',
    };

    return Map[type] || '/400';
  };

  useEffect(() => {
    if (!isLoggedIn) return;

    (async () => {
      const lineAccessToken = liff.getAccessToken()
      Cookies.set('lineAccessToken', lineAccessToken);
      console.log(lineAccessToken)

      userHttpServices.lineLogin(lineAccessToken)
        .then((res) => {
          console.log('lineAccessToken at ok', lineAccessToken)
          console.log('res', res)

          // let loginData = res.data.data
          localStorage.setItem("brand", res.data?.data?.brand)
          localStorage.setItem("product", res.data?.data?.product);
          
          window.location.href = controller(res.data?.message_code)
        })
        .catch(err => {
          console.log('lineAccessToken at err', lineAccessToken)
          alert('[app L53] Error have had been found')
        }
        )
    })();

    // const lineAccessToken = 'eyJhbGciOiJIUzI1NiJ9.xlADbvAN_BJqEOtKkiQysJcqmWJehAyCC8D6G3MTa88a2Tp2_Ub0w3PeMz1WMbxty3okxbnCuBNDW5Wqw-qaLoLfq_H9GVxzbfIORIV4ixEwwP-6_DTXA4XRVQTx3rMqvdha7uqEeuG535GZ1VBVIfjMCwec8KI-iKg0loLE21g.PN4--bOmtehDoYuQoeppM5vqsdhZPEGcnLP9YtLUQfY'
    // Cookies.set('lineAccessToken', lineAccessToken);
    // window.location.href = '/home'

  }, [liff, isLoggedIn]);

  const showLoginButton = () => {
    if (error) return <p>Something is wrong.</p>;
    if (!ready) return <p>Loading...</p>;

    if (!isLoggedIn) {
      console.log(isLoggedIn)
      return (
        <>
          <br />
          <Button variant="select-md" onClick={liff.login}>
            เข้าสู่ระบบ
          </Button>
        </>
      );
    } else {
      // return (
      // <>
      // <br />
      // <Button variant="select-md" onClick={liff.login}>
      // เข้าสู่ระบบใหม่
      // </Button>
      // </>
      // )
    }
  };

  return (
    <div className="center" style={{ maxWidth: '30rem' }}>
      <div className="center" style={{ paddingTop: '50px' }}>
        <img src={LogoPico} alt="" style={{ width: '250px', margin: '30px' }} />
      </div>

      <br />
      {showLoginButton()}
    </div>
  )

}

export default App;