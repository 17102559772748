import React from 'react'
import { Button, Row } from 'react-bootstrap'
import { useLocation } from 'react-router-dom';
import Unsuccess from '../assets/images/2.png';
import LogoPico from './../assets/images/Logo-Pico-เงินด่วน.png';
// import LogoCashkrub from './../assets/images/CASH-KRUB-LOGO-02-white.png';

const UploadUnSuccess = () => {
    const location = useLocation();
    const failCounter = location.state?.counter;
    // const brand = localStorage.getItem("brand");
    // const logoSource = brand === 'CASHKRUB' ? LogoCashkrub : LogoMscw;

    return (
        <div className='center' style={{
            maxWidth: '30rem',
            minHeight: '100vh',
            boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px'
        }}>
            <div className='center' style={{ paddingTop: '50px' }}>
                <img src={LogoPico} alt="" style={{ width: '250px', margin: '30px' }} />
            </div>

            <div style={{ padding: '45px' }}>
                <div className='m-3'>
                    <img src={Unsuccess} alt="unsuccess" width="62px" />
                </div>

                <br />
                <h3>อัปโหลดไม่สำเร็จ {failCounter && `${failCounter} รายการ`}</h3>
                <p>กรุณาตรวจสอบเอกสารใหม่อีกครั้ง</p>

                <div style={{ marginTop: '10rem' }}>
                    <Row>
                        <Button variant="secondary" onClick={() => window.history.back()}>อัปโหลดเอกสารอื่นๆ</Button>
                    </Row>
                </div>
            </div>
        </div>
    )
}

export default UploadUnSuccess
