/* eslint-disable no-unused-vars */
import axios from 'axios';
import config from '../config.json';

const QueryString = require('qs');

export const lineRegister = (lineAccessToken, data) => {
    console.log('lineRegister :', lineAccessToken, data)
    const body = QueryString.stringify(data);
    return axios.post(config.DEV_API_URL + '/user/line_register', body, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer ' + lineAccessToken
        },
    })
}

export const editUser = (data) => {
    const body = QueryString.stringify(data);
    return axios.post(config.DEV_API_URL + '/user/edit_user', body, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            // 'Authorization': 'Bearer ' + lineAccessToken
        },
    })
}

export const lineLogin = (lineAccessToken) => {
    console.log('lineAccessToken', lineAccessToken)
    return axios.post(config.DEV_API_URL + '/user/line_login', {}, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer ' + lineAccessToken,
        },
    });
};

export const uploadfile = (data) => {
    return axios.post(config.DEV_UPLOAD_API_URL + '/upload_file', data, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        maxContentLength: Infinity,
        maxBodyLength: Infinity,
    })
}

export const deletefile = (data) => {
    return axios.post(config.DEV_UPLOAD_API_URL + '/delete_file', data, {
        headers: {
            'Content-Type': 'application/json'
        },
    })
}

export const requestOTP = (data) => {
    console.log('requestOTP', data)
    return axios.post(config.DEV_API_URL + '/otp/check', data, {
        headers: {
            'Content-Type': 'application/json'
        },
    })
}

export const verifyOTP = (data, lineAccessToken) => {
    console.log('verifyOTP', data, lineAccessToken)
    const body = QueryString.stringify(data);

    return axios.post(config.DEV_API_URL + '/otp/verify', body, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer ' + lineAccessToken,
        },
    })
}

export const updateUserToCancle = (data) => {
    return axios.post(config.DEV_API_URL + '/user/update_user_cancle', data, {
        headers: {
            'Content-Type': 'application/json'
        },
    })
}

export const updatePreScreen = (data) => {
    return axios.post(config.DEV_API_URL + '/user/update_prescreen', data, {
        headers: {
            'Content-Type': 'application/json'
        },
    })
}

export const updateHupspot = (data) => {
    return axios.post('https://mkt.pico.tfm.in.th/api/hubspot', data, {
        headers: {
            'Content-Type': 'application/json'
        },
    })
}

export const newLineRegister = (data, lineAccessToken) => {
    const body = QueryString.stringify(data);
    return axios.post(`${config.DEV_API_URL}/user/shortcut_line_register`, body, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer ' + lineAccessToken
        },
    })
}

// export const uploadfile = (data) => {
//     // Default options are marked with *
//     fetch('https://master-api.transfinmational.org/upload_file', {
//         method: "POST", // *GET, POST, PUT, DELETE, etc
//         headers: {
//             'Content-Type': 'multipart/form-data',
//             // 'Content-Type': 'application/x-www-form-urlencoded',
//         },
//         body: JSON.stringify(data), // body data type must match "Content-Type" header
//     })
//     .then((res)=>console.log(res))
//     // console.log(response.json()) // parses JSON response into native JavaScript objects
// }

// export const uploadfile = (formData) => {
//     fetch('https://master-api.transfinmational.org/upload_file', {
//         method: 'POST',
//         body: formData
//     })
//         .then(response => response.json())
//         .then(data => console.log(data))
//         .catch(error => console.error(error));
// }


// export const uploadfile = (formData) => {

//     fetch('https://master-api.transfinmational.org/upload_file', {
//         method: 'POST',
//         headers: {
//             'Content-Type': 'application/json'
//         },
//         body: JSON.stringify({
//             formData
//         })
//     })
//         .then(response => response.json())
//         .then(data => {
//             // Handle the response data
//             console.log(data);
//         })
//         .catch(error => {
//             // Handle any errors
//             console.error(error);
//         });
// }