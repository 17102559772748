import React from 'react'
import { Modal } from 'react-bootstrap'
import CloseButton from 'react-bootstrap/CloseButton';
import LogoPico from './../../assets/images/Logo-Pico-เงินด่วน.png';
// import LogoCashkrub from './../../assets/images/CASH-KRUB-LOGO-02-white.png';

const Model = ({ show, handleClose }) => {
    // const brand = localStorage.getItem("brand");
    // const logoSource = brand === 'CASHKRUB' ? LogoCashkrub : LogoMscw;

    return (
        <div>
            <Modal show={show} onHide={handleClose}>
                <Modal.Body>
                    <div className="right">
                        <CloseButton style={{ backgroundColor: '#fff', padding: '10px', marginBottom: '20px', fontSize: '12px' }} onClick={handleClose} />
                    </div>
                    <div className="center mb-4">
                        <p style={{ fontWeight: '16px', textDecoration: 'underline' }}>สัญญาเงินกู้</p>
                        <img src={LogoPico} className="logo center" alt="" />
                    </div>
                    <p style={{ marginBottom: '5rem', fontSize: '12px' }}>
                        สัญญาฉบับนี้จะเรียกว่า &quot;ผู้กู้&quot; ได้ทำสัญญาฉบับนี้ให้ไว้แก่ บริษัท พิโก โซลูชั่นส์
                        (ไทยแลนด์) จำกัด ซึ่งต่อไปในสัญญาฉบับนี้จะเรียกว่า &quot;ผู้ให้กู้&quot; โดยมีรายละเอียด
                        และเงื่อนไขของสัญญาดังนี้<br />
                        <br />

                        <b>ข้อ 1. ผู้ให้กู้ตกลงให้กู้และผู้กู้ตกลงกู้เงิน ตามจำนวนเงินที่ระบุไว้หนังสือแจ้งผลการอนุมัติการปล่อยสินเชื่อ </b>
                        ซึ่งมีทั้งรายละเอียดของเงื่อนไงในการกู้เงินและการชำระเงินคืนให้กับผู้ให้กู้
                        และเมื่อ ได้โอนเงินเข้าบัญชีของผู้กู้แล้วให้ถือว่าผู้กู้ได้รับเงินกู้ตามสัญญาฉบับนี้ครบถ้วนแล้ว
                        โดยให้ถือเอาแบบคำขอสินเชื่อบุคคลและหนังสือแจ้งผลการอนุมัติการปล่อยสินเชื่อ
                        เป็นส่วนหนึ่งของสัญญากู้ยืมเงินฉบับนี้ด้วยเช่นกัน<br />
                        <br />

                        <b>ข้อ 2. ผู้กู้ตกลงชำระดอกเบี้ยในอัตราร้อยละ 25 - 35.88% ต่อปี </b>
                        พร้อมค่าใช้จ่ายต่างๆ ที่จะได้แจ้งให้ทราบตามเงื่อนไขที่บริษัทได้กำหนดไว้
                        จากต้นเงินตามสัญญากู้เงินที่เหลืออยู่ และยังมิได้ชำระคืนแก่ผู้ให้กู้
                        นับแต่วันที่ผู้ให้กู้ได้นำเงินเข้าบัญชีดังกล่าวเป็นต้น
                        จนกว่าจะได้ชำระเสร็จสิ้นตามระยะเวลาที่กำหนดไว้ในสัญญาฉบับนี้
                        โดยผู้กู้ยินยอมให้บริษัททำการหักค่าใช้จ่ายต่างๆ ในการทำสัญญากู้เงินเสียก่อน
                        ณ วันที่รับเงินกู้<br />
                        <br />

                        <b>ข้อ 3. ในการชำระเงินกู้ตามข้อ 1.นั้น
                            ผู้กู้ตกลงชำระเงินให้ผู้ให้กู้โดยจะทำการโอนเงินเข้าบัญชีของผู้ให้กู้
                            ซึ่งเป็นบัญชีของธนาคาร ดังต่อไปนี้ </b>
                        <br /><b>&emsp;&emsp;3.1 ธนาคารกรุงเทพ เลขที่ 105-502972-0 ชื่อบัญชี บริษัท พิโก โซลูชั่นส์ (ไทยแลนด์) จำกัด</b>
                        <br /><b>&emsp;&emsp;3.2 ธนาคารกสิกรไทย เลขที่ 031-8-56075-7 ชื่อบัญชี บริษัท พิโก โซลูชั่นส์ (ไทยแสนด์) จำกัด</b>
                        <br /><b>&emsp;&emsp;3.3 กรณีที่ผู้กู้ได้ทำการยินยอมให้บริษัทฯ
                            ใช้บริการตัดเงินอัตโนมัติเพื่อหักค่างวดชำระตามสัญญาเงินกู้ฉบับนี้
                            ผู้กู้ยินยอมให้บริษัทฯ ทำรายการตั้งแต่วันที่ 25
                            ของทุกเดือนจนกว่าจะชำระค่างวดเสร็จสิ้น </b>
                        <br />เพื่อเป็นการชำระหนี้เงินกู้ดังกล่าว แต่หากวันที่ถึงกำหนดชำระตรงกับวันหยุดทำการของผู้ให้กู้แล้ว ให้เลื่อนวันถึงกำหนดชำระเป็นวันทำการสุดท้ายก่อนหน้าวันที่ถึงกำหนดชำระดังกล่าว <br />
                        <br />

                        <b>ข้อ 4. ผู้กู้ตกลงว่าหากผู้กู้ถึงแก่กรรมแล้ว ให้ถือว่าหนี้เงินกู้ที่เหลืออยู่ตามสัญญาฉบับนี้ </b>
                        เป็นอันถึงกำหนดชำระโดยทันที
                        โดยผู้ให้กู้มีสิทธิขอหักเงินได้ซึ่งเป็นของผู้กู้ หรือเงินอื่นใดอันผู้กู้จะพึงได้รับ
                        เพื่อชำระเงินกู้ที่เหลืออยู่พร้อมดอกเบี้ยที่ค้างชำระ (ถ้ามี)
                        สัญญาฉบับนี้ให้แก่ผู้ให้กู้โดยทันที
                        แต่หากเงินที่ได้รับไม่เพียงพอต่อการชำระหนี้ที่ค้างชำระอยู่กับผู้ให้กู้แล้ว
                        ผู้ให้กู้มีสิทธิเรียกร้องจากบุคคลอื่นตามสิทธิที่กฎหมายกำหนดไว้จนครบถ้วน<br />
                        <br />

                        <b>ข้อ 5. ในกรณีต่อไปนี้ให้ถือว่าผู้กู้ผิดสัญญา และผู้ให้กู้มีสิทธิบอกเลิกสัญญาได้ ไม่ว่าจะเป็นกรณีที่ผู้กู้ไม่ปฏิบัติตามสัญญาฉบับนี้ไม่ว่าข้อใดข้อหนึ่ง </b>
                        หรือผู้กู้ผิดนัดชำระคืนเงินต้น ดอกเบี้ย
                        หรือค่าใช้จ่ายตามเงื่อนไขที่กำหนดไว้ในสัญญาฉบับนี้
                        หรือมีการดำเนินการอย่างใดๆ
                        ด้วยข้อความอันเป็นเท็จที่ควรจะต้องแจ้งให้ผู้ให้กู้ทราบ
                        หรือต้องคำพิพากษาของศาล หรือถูกศาลมีคำสั่งพิทักษ์ทรัพย์เด็ดขาด
                        หรือสิ้นสภาพบุคคลตามที่กฎหมายกำหนด
                        ให้ถือว่าสัญญาฉบับนี้เป็นอันถึงกำหนดชำระและผู้กู้จะต้องชำระเงินต้นที่เหลือ
                        ดอกเบี้ย และค่าใช้จ่าย ที่ค้างชำระ (ถ้ามี) คืนให้กับผู้ให้กู้โดยทันที
                        ทั้งนี้การแก้ไขการผิดสัญญาทำได้โดยการชำระหนี้ที่ค้างชำระทั้งหมด
                        หรือตามจำนวนงวดที่ค้างชำระทั้งหมดเท่านั้น
                        หากผู้กู้ไม่แก้ไขตามข้อความดังกล่าว ผู้ให้กู้มีสิทธิบอกเลิกสัญญา<br />
                        <br />

                        <b>ข้อ 6. ผู้กู้ยินยอมให้ผู้ให้กู้โอนสิทธิ หน้าที่ และผลประโยชน์ใดๆ </b>
                        ตามสัญญาฉบับนี้ไม่ว่าจะทั้งหมดหรือแต่บางส่วนให้แก่บุคคลภายนอกได้ ไม่ว่าจะเป็นนิติบุคคลหรือบุคคลธรรมดา โดยผู้ให้
                        กู้จะทำการบอกกล่าวแจ้งการโอนสิทธิให้ผู้กู้ทราบเป็นหนังสือภายในระยะเวลาพอสมควร เว้นแต่ในกรณีที่มีกฎหมาย กำหนดไว้โดยเฉพาะว่าไม่ต้องทำการบอกกล่าว หรือต้องแจ้งให้ผู้กู้ทราบ
                        แต่ผู้กู้จะโอนสิทธิ หรือประโยชน์หรือหน้าที่ ไม่ว่าทั้งหมดหรือเพียงบางส่วนที่มีอยู่ตามสัญญาฉบับนี้ให้บุคคลใดๆไม่ได้โดยเด็ดขาด<br />
                        <br />

                        <b>ข้อ 7. ผู้กู้ยอมรับว่าอัตราดอกเบี้ยและค่าใช้จ่าย ที่กำหนดไว้ในคำขอและ/หรือสัญญานี้</b> รวมทั้งข้อตกลงตามที่กำหนดไว้ในคำขอ และเหรือสัญญานี้ เป็นไปโดยมีวัตถุประสงค์เพื่อให้สอดคล้อง
                        กับหลักเกณฑ์ของทางราชการที่ใช้บังคับอยู่ในขณะทำสัญญาฉบับนี้ แต่หากได้มีการประกาศหรือแก้ไขเปลี่ยนแปลงหลักเกณฑ์ของทางราชการ และผู้ให้กู้จำต้องแก้ไขหรือเปลี่ยนแปลงตาม
                        หลักเกณฑ์ของทางราชการที่ประกาศใหม่แล้ว ผู้กู้ยินยอมให้ผู้ให้กู้ทำการเปลี่ยนแปลงได้ทันที โดยผู้ให้กู้จะได้แจ้งให้ทราบเป็นหนังสือ<br />
                        <br />

                        <b>ข้อ 8. ผู้กู้ตกลงว่าใบคำขอและบรรดาหนังสือแจ้งผลการพิจารณาสินเชื่อ </b>
                        หรือเอกสารอื่นใดที่ผู้ให้กู้ได้แจ้งให้ผู้กู้ทราบเกี่ยวกับจำนวนเงินกู้
                        ที่ผู้ให้กู้ตกลงให้กู้ งวดการผ่อนชำระหนี้ จำนวนเงินที่ผ่อนชำระหนี้แต่ละงวด
                        อัตราดอกเบี้ย วันถึงกำหนดชำระหนี้ ค่าอากรแสตมป์ และ/หรือข้อกำหนด
                        และ/หรือเงื่อนไขอื่นใดเกี่ยวกับการกู้เงินตามสัญญาฉบับนี้
                        เป็นส่วนหนึ่งของสัญญากู้เงินฉบับนี้ด้วย ซึ่งผู้กู้ตกลงผูกพันทุกประการ<br />
                        <br />

                        <b>ข้อ 9. ในส่วนของค่าอากรแสตมป์ ที่ใช้ในการทำสัญญาฉบับนี้ </b>
                        และ/หรือเอกสารต่างๆ ค่าใช้จ่ายต่างๆ เช่น ค่าบริการจดทะเบียนสิทธิ
                        และนิติกรรม ค่าจัดเตรียมเอกสารสัญญา นั้นผู้กู้ตกลงชำระแต่เพียงผู้เดียว
                        และหากผู้กู้ผิดสัญญาแล้วผู้กู้ยอมรับผิดในค่าเสียหายต่างๆ ที่เกิดขึ้น
                        รวมทั้งค่าใช้จ่ายในการบอกกล่าวทวงถาม ค่าทนายความ
                        ดำเนินคดีฟ้องร้องต่อศาล และ/หรือ เพื่อบังคับชำระหนี้ตามสัญญานี้
                        รวมทั้งค่าฤชารรรมเนียมใดๆ อันจะเกิดขึ้น
                        ผู้กู้ยินยอมชำระแต่ฝ่ายเดียวทั้งสิ้นเช่นกัน<br />
                        <br />

                        <b>ข้อ 10. หากเงื่อนไขในสัญญาฉบับนี้ข้อใดข้อหนึ่ง </b>
                        และ/หรือ ส่วนหนึ่งส่วนใดของสัญญาฉบับนี้ตกเป็นโมฆะกรรมแล้ว ให้เงื่อนไขในข้ออื่น ๆ
                        และ/หรือ ส่วนอื่นๆ ของสัญญาฉบับนี้ยังคงสมบูรณ์
                        และยังคงมีผลผูกพันแต่ละฝ่ายต่อไป<br />
                        <br />

                        <b>ข้อ 11. ในการส่งหนังสือบอกกล่าวทวงถามหรือเอกสารใดๆไปยังภูมิลำเนาของผู้กู้ตามที่ปรากฏอยู่ในสัญญาฉบับนี้ </b>
                        ส่งทางไปรษณีย์ลงทะเบียนตอบรับ ให้ถือว่าเป็นการส่งโดยชอบ
                        ถึงแม้ว่าผู้กู้จะมิได้รับด้วยตนเองก็ตาม
                        หรือการส่งโทรสารหรือจดหมายอิเล็กทรอนิกส์ หรือช่องทางอิเล็กทรอนิกส์
                        ก็ให้ถือว่าได้รับทราบข้อความนั้นแล้ว
                        และหากผู้กู้มีแจ้งการย้ายหรือเปลี่ยนแปลงภูมิลำเนาแล้ว
                        ต้องทำเป็นหนังสือแจ้งให้ผู้ให้กู้ทราบด้วย
                        และผู้กู้ยินยอมให้แจ้งข้อมูลอนุมัติหรือปฏิเสธสินเชื่อผ่านช่องทางอิเล็กทรอนิกส์ได้
                        ช่องทางที่ทำได้คือ Line Official Account และ/หรือ ทางจดหมายอิเล็กทรอนิกส์ (Email)
                        ได้เท่านั้น
                    </p>
                    <div className="right">
                        <CloseButton style={{ backgroundColor: '#fff', padding: '10px', marginBottom: '20px', fontSize: '12px' }} onClick={handleClose} />
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default Model

