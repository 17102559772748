import React from 'react'
import { Col, Form, Row } from 'react-bootstrap';
import provincesData from '../../datas/provinces.json';


const Address = ({ values, name, isError, handleChange, disabled }) => {

    const provinceNameTh = values.province;
    const index = provincesData.PROVINCES.findIndex((province) => province.name_th === provinceNameTh);

    const countyNameTh = values.county
    const index2 = provincesData.PROVINCES[index]?.amphure?.findIndex((amphure) => amphure.name_th === countyNameTh);

    const districtNameTh = values.district
    const index3 = provincesData.PROVINCES[index]?.amphure[index2]?.tambon?.findIndex((tambon) => tambon.name_th === districtNameTh);

    const getOptions = (data, key) => {
        return data?.map(item => item[key]) || [];
    };

    const options1 = () => getOptions(provincesData.PROVINCES, 'name_th');
    const options2 = () => getOptions(provincesData.PROVINCES[index]?.amphure, 'name_th');
    const options3 = () => getOptions(provincesData.PROVINCES[index]?.amphure[index2]?.tambon, 'name_th');
    const options4 = () => [provincesData.PROVINCES[index]?.amphure[index2]?.tambon[index3]?.zip_code]

    return (
        <div>
            <Row>
                <Col xs="12" style={{ padding: 5 }}>
                    <Form.Group className="mb-2">
                        <Form.Control
                            size="md"
                            placeholder="ข้อมูลที่อยู่"
                            type="text"
                            name={name.address}
                            value={values.address}
                            onChange={handleChange}
                            isInvalid={isError.err?.[name.address]}
                            disabled={disabled}
                        />
                        <Form.Control.Feedback type="invalid" >
                            {isError.message?.[name.address]}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col xs="6" style={{ padding: 5 }}>
                    <Form.Group className="mb-2">
                        <Form.Control
                            as="select"
                            size="md"
                            type="text"
                            name={name.province}
                            value={values.province}
                            style={{ color: values.province === "" ? '#6d757d' : '' }}
                            isInvalid={isError.err?.[name.province]}
                            onChange={handleChange}
                            disabled={disabled}
                        >
                            <option value="" defaultValue disabled>จังหวัด</option>
                            {
                                Array.from(options1(), (item, index) => {
                                    return <option key={index} value={item}>{item}</option>
                                })
                            }
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            {isError.message?.[name.province]}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col xs="6" style={{ padding: 5 }}>
                    <Form.Group className="mb-2">
                        <Form.Control
                            as="select"
                            size="md"
                            type="text"
                            name={name.county}
                            value={values.county}
                            isInvalid={isError.err?.[name.county]}
                            style={{ color: values.county === "" ? '#6d757d' : '' }}
                            onChange={handleChange}
                            disabled={disabled}
                        >
                            <option value="" defaultValue disabled>แขวง/ตำบล</option>
                            {
                                Array.from(options2(), (item, index) => {
                                    return <option key={index} value={item}>{item}</option>
                                })
                            }
                        </Form.Control>
                        <Form.Control.Feedback type="invalid" >
                            {isError.message?.[name.county]}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col xs="6" style={{ padding: 5 }}>
                    <Form.Group className="mb-2">
                        <Form.Control
                            as="select"
                            size="md"
                            type="text"
                            name={name.district}
                            value={values.district}
                            isInvalid={isError.err?.[name.district]}
                            style={{ color: values.district === "" ? '#6d757d' : '' }}
                            onChange={handleChange}
                            disabled={disabled}
                        >
                            <option value="" defaultValue disabled>เขต/อำเภอ</option>
                            {
                                Array.from(options3(), (item, index) => {
                                    return <option key={index} value={item}>{item}</option>
                                })
                            }
                        </Form.Control>
                        <Form.Control.Feedback type="invalid" >
                            {isError.message?.[name.district]}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col xs="6" style={{ padding: 5 }}>
                    <Form.Group className="mb-2">
                        <Form.Control
                            as="select"
                            size="md"
                            type="text"
                            name={name.zipcode}
                            value={values.zipcode}
                            isInvalid={isError.err?.[name.zipcode]}
                            style={{ color: values.zipcode === "" ? '#6d757d' : '' }}
                            onChange={handleChange}
                            disabled={disabled}
                        >
                            <option value="" defaultValue disabled>รหัสไปรษณีย์</option>
                            {
                                Array.from(options4(), (item, index) => {
                                    return <option key={index} value={item}>{item}</option>
                                })
                            }
                        </Form.Control>
                        <Form.Control.Feedback type="invalid" >
                            {isError.message?.[name.zipcode]}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
            </Row>
        </div >
    )
}

export default Address

// onChange={(event) =>
//     setValues((prevState) => ({
//         ...prevState,
//         radioGroupAddress2: '0',
//         [event.target.name]: event.target.value,
//     }))
// }
