import React, { useState } from 'react'
import OtpInput from 'react-otp-input';
import * as userHttpServices from '../../services/digital-app-services'
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';
import LogoPico from './../../assets/images/Logo-Pico-เงินด่วน.png';
// import LogoCashkrub from './../../assets/images/CASH-KRUB-LOGO-02-white.png';

const ValidateOTP = ({ phone }) => {
    const [otp, setOtp] = useState('');

    const HandelOtpInput = (password) => {
        if (otp.length <= 4) {
            setOtp(password);
        }

        if (password.length === 4) {
            const jsonData = {
                phone_number: phone,
                otp: password
            }

            const lineAccessToken = Cookies.get('lineAccessToken');

            userHttpServices.verifyOTP(jsonData, lineAccessToken)
                .then((res) => {
                    console.log('>>>>',res)
                    if (res.data.success) {
                        handleSuccess(res)
                    } else {
                        handleError()
                    }
                })
                .catch((err) => alert('[online L123] Error have had been found'))
        }
    }

    const handleError = () => {
        Swal.fire({
            icon: 'error',
            html: 'รหัสไม่ถูกต้อง<br>กรุณากรอกใหม่อีกครั้ง',
            showConfirmButton: false,
            timer: 2000, // 2 seconds
            timerProgressBar: true,
            backdrop: false,
            customClass: {
                container: 'custom-swal-container', // Add a custom class to the container
            },
            willOpen: () => {
                // Add custom styling to the modal
                const modal = Swal.getPopup();
                modal.style.borderRadius = '30px';
            },
        }).then(() => {
            setOtp('')
        })
    }

    const handleSuccess = (res) => {
        Swal.fire({
            icon: 'success',
            html: 'รหัสถูกต้อง',
            showConfirmButton: false,
            timer: 2000, // 2 seconds
            timerProgressBar: true,
            backdrop: false,
            customClass: {
                container: 'custom-swal-container', // Add a custom class to the container
            },
            willOpen: () => {
                // Add custom styling to the modal
                const modal = Swal.getPopup();
                modal.style.borderRadius = '30px';
            },
        }).then(() => {
            localStorage.setItem("brand", res.data?.brand);
            localStorage.setItem("product", res.data?.product);

            window.location.href = `/${res.data.message}`
        })
    }

    const isOtpComplete = otp.length === 4;

    return (
        <div className='center' style={{ maxWidth: '30rem' }}>
            <div className="center" style={{ paddingTop: '50px' }}>
                <div style={{ paddingLeft: '20px' }}>
                    <img src={LogoPico} alt="" style={{ width: '250px', margin: '30px' }} />
                    {/* <img src={LogoCashkrub} alt="" style={{ width: '140px', marginLeft: '20px', marginTop: '20px' }} /> */}
                </div>
            </div>
            <div style={{ padding: '45px' }}>
                <p className="p-2" style={{ fontWeight: 400, fontSize: 18 }}>กรุณาระบุ OTP ที่ได้รับ</p>

                <div className="center" style={{ width: '300px', marginTop: '2rem' }}>
                    <OtpInput
                        value={otp}
                        onChange={HandelOtpInput}
                        numInputs={4}
                        inputType="tel"
                        containerStyle={{ display: "flex", justifyContent: 'space-between' }}
                        inputStyle={{ borderRadius: '50%', height: '55px', width: '55px', border: 'none' }}
                        renderInput={(props) => (
                            < input
                                {...props}
                                disabled={isOtpComplete}
                            />
                        )}
                    />
                </div>
            </div>
        </div>
    )
}

export default ValidateOTP
