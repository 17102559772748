import React from 'react'
import { Col, Row, Button, Form } from 'react-bootstrap';
import Genders from '../../datas/genders.json'
import Months from '../../datas/months.json'
import { padLeadingZeros } from '../../../src/utils';
import { validPhone } from '../../../src/assets/regex';
import Address from './address';

const FormEditor1 = ({ isError, setIsError, values, setValues, setActiveStep, validateForm }) => {
    const handleSubmit = (event) => {
        event.preventDefault();

        const hasErrorValue = validateForm()
        if(hasErrorValue) return

        setActiveStep((prevActiveStep) => ({ current: prevActiveStep.current + 1, top: 2 }));
    };

    const handleChange = (event) => {
        const { name, value } = event.target

        setValues((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    }

    return (
        <div>
            <Form onSubmit={handleSubmit}>
                <Form.Group>
                    <Row>
                        <Col xs="4" style={{ padding: 5 }}>
                            <Form.Group className="mb-2">
                                <Form.Control
                                    as="select"
                                    size="md"
                                    type="text"
                                    name="gender"
                                    value={values.gender}
                                    onChange={handleChange}
                                    isInvalid={isError.err?.gender}
                                    style={{ color: values.gender === "" ? '#6d757d' : '' }}
                                >
                                    <option value='' defaultValue disabled>คำนำหน้า</option>
                                    {
                                        Array.from(Genders.genders, (item, index) => {
                                            return <option key={index} value={item}>{item}</option>
                                        })
                                    }
                                </Form.Control>
                                <Form.Control.Feedback type="invalid" >
                                    โปรดเลือกคำนำหน้าชื่อ
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col xs="8" style={{ padding: 5 }}>
                            <Form.Group className="mb-2">
                                <Form.Control
                                    size="md"
                                    placeholder="ชื่อ"
                                    type="text"
                                    name="firstName"
                                    value={values.firstName}
                                    onChange={handleChange}
                                    isInvalid={isError.err?.firstName}
                                />
                                <Form.Control.Feedback type="invalid" >
                                    {isError.message?.firstName}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>

                        <Col xs="12" style={{ padding: 5 }}>
                            <Form.Group className="mb-2">
                                <Form.Control
                                    size="md"
                                    placeholder="นามสกุล"
                                    type="text"
                                    name="lastName"
                                    value={values.lastName}
                                    onChange={handleChange}
                                    isInvalid={isError.err?.lastName}
                                />
                                <Form.Control.Feedback type="invalid" >
                                    {isError.message?.lastName}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>

                        <Col xs="4" style={{ padding: 5 }}>
                            <Form.Group className="mb-2">
                                <Form.Control
                                    as="select"
                                    size="md"
                                    placeholder="วัน"
                                    type="text"
                                    name="birthDate"
                                    value={values.birthDate}
                                    onChange={handleChange}
                                    isInvalid={isError.err?.birthDate}
                                    style={{ color: values.birthDate === "" ? '#6d757d' : '' }}

                                >
                                    <option value='' defaultValue disabled> วัน </option>
                                    {
                                        Array.from(Array(31), (e, i) => {
                                            return <option key={i} value={i + 1}>{padLeadingZeros(i + 1, 2)}</option>
                                        })
                                    }
                                </Form.Control>
                                <Form.Control.Feedback type="invalid" >
                                    {isError.message?.birthDate}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col xs="4" style={{ padding: 5 }}>
                            <Form.Group className="mb-2">
                                <Form.Control
                                    as="select"
                                    size="md"
                                    placeholder="เดือน"
                                    type="text"
                                    name="birthMonth"
                                    value={values.birthMonth}
                                    onChange={handleChange}
                                    isInvalid={isError.err?.birthMonth}
                                    style={{ color: values.birthMonth === "" ? '#6d757d' : '' }}

                                >
                                    <option value='' defaultValue disabled> เดือน </option>
                                    {
                                        Array.from(Array(12), (item, index) => {
                                            return <option key={index} value={index + 1}>{Months.months[index]}</option>
                                        })
                                    }
                                </Form.Control>
                                <Form.Control.Feedback type="invalid" >
                                    {isError.message?.birthMonth}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col xs="4" style={{ padding: 5 }}>
                            <Form.Group className="mb-2">
                                <Form.Control
                                    as="select"
                                    size="md"
                                    placeholder="ปีเกิด"
                                    type="text"
                                    name="birthYear"
                                    value={values.birthYear}
                                    onChange={handleChange}
                                    isInvalid={isError.err?.birthYear}
                                    style={{ color: values.birthYear === "" ? '#6d757d' : '' }}
                                >
                                    <option value='' defaultValue disabled> ปีเกิด </option>
                                    {
                                        Array.from(Array(66), (e, i) => {
                                            return <option key={i} value={2550 - 543 - i}>{2550 - i}</option>
                                        })
                                    }
                                </Form.Control>
                                <Form.Control.Feedback type="invalid" >
                                    {isError.message?.birthYear}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col xs="12" style={{ padding: 5 }}>
                            <Form.Group className="mb-2">
                                <Form.Control
                                    size="md"
                                    placeholder="เลขบัตรประชาชน"
                                    type="number"
                                    name="identity"
                                    value={values.identity}
                                    onChange={handleChange}
                                    isInvalid={isError.err?.identity}
                                    disabled={values.haveData}
                                />
                                <Form.Control.Feedback type="invalid" >
                                    {isError.message?.identity}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>

                        <Col xs="6" style={{ padding: 5 }}>
                            <Form.Group className="mb-2">
                                <Form.Control
                                    size="md"
                                    placeholder="อีเมล"
                                    type="email"
                                    name="email"
                                    value={values.email}
                                    onChange={handleChange}
                                    isInvalid={isError.err?.email}
                                />
                                <Form.Control.Feedback type="invalid" >
                                    {isError.message?.email}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col xs="6" style={{ padding: 5 }}>
                            <Form.Group className="mb-2">
                                <Form.Control
                                    size="md"
                                    placeholder="เบอร์มือถือ"
                                    type="tel"
                                    name="phone"
                                    value={values.phone}
                                    onChange={handleChange}
                                    isInvalid={isError.err?.phone}
                                />
                                <Form.Control.Feedback type="invalid" >
                                    {isError.message?.phone}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>

                        <Col xs="6" style={{ padding: 5 }}>
                            <Form.Group className="mb-2">
                                <Form.Control
                                    size="md"
                                    placeholder="ID Line"
                                    type="text"
                                    name="line"
                                    value={values.line}
                                    onChange={handleChange}
                                    isInvalid={isError.err?.line}
                                />
                                <Form.Control.Feedback type="invalid" >
                                    {isError.message?.line}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col xs="6" style={{ padding: 5 }}>
                            <Form.Group className="mb-2">
                                <Form.Control
                                    size="md"
                                    placeholder="Facebook"
                                    type="text"
                                    name="facebook"
                                    value={values.facebook}
                                    onChange={handleChange}
                                    isInvalid={isError.err?.facebook}
                                />
                                <Form.Control.Feedback type="invalid" >
                                    {isError.message?.facebook}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>

                    <p style={{ fontWeight: 300, fontSize: 16 }}>ที่อยู่ตามบัตรประชาชน</p>
                    <Address
                        values={{
                            address: values.address,
                            province: values.province,
                            county: values.county,
                            district: values.district,
                            zipcode: values.zipcode
                        }}
                        name={{
                            address: 'address',
                            province: 'province',
                            county: 'county',
                            district: 'district',
                            zipcode: 'zipcode'
                        }}
                        isError={isError}
                        handleChange={handleChange}
                    />

                    <p style={{ fontWeight: 300, fontSize: 16, textAlign: 'left' }}>ประวัติการศึกษา</p>
                    <Row>
                        <Col xs="12" style={{ padding: 5 }}>
                            <Form.Group className="mb-2">
                                <Form.Control
                                    as="select"
                                    size="md"
                                    placeholder="ประวัติการศึกษาสูงสุด"
                                    type="text"
                                    name="educational"
                                    value={values.educational}
                                    onChange={handleChange}
                                    isInvalid={isError.err?.educational}
                                    style={{ color: values.educational === "" ? '#6d757d' : '' }}
                                >
                                    <option value='' defaultValue disabled>ประวัติการศึกษาสูงสุด</option>
                                    <option value='มป'>มัธยมศึกษาตอนปลาย</option>
                                    <option value='ปวช'>อนุปริญญา/ปวช./ปวส.</option>
                                    <option value='ปต'>ปริญญาตรี</option>
                                    <option value='ปท'>ปริญญาโท ขึ้นไป</option>
                                    <option value='ออ'>อื่นๆ</option>
                                </Form.Control>
                                <Form.Control.Feedback type="invalid" >
                                    {isError.message?.educational}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>

                    <div className="mt-5">
                        <Row>
                            <Button variant="secondary" type="submit" style={{ borderRadius: '10px' }}>ต่อไป</Button>
                            <Button variant="danger" style={{ borderRadius: '10px', marginTop: '20px', display: values.haveData ? '' : 'none' }} onClick={() => window.history.back()}>ยกเลิกแก้ไขข้อมูล</Button>
                        </Row>
                    </div>
                </Form.Group>
            </Form >
        </div>
    )
}

export default FormEditor1
