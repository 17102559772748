import React from 'react'
import { Form } from 'react-bootstrap'
import EzCal from '../../assets/images/EzCal600-1 1.png'
import Swal from 'sweetalert2';

const MicroSite = ({ step, values, setValues }) => {

    const handleRadioChange = (e) => {
        const { name, value } = e.target
        setValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    };

    const handleError = () => {
        Swal.fire({
            icon: 'error',
            html: 'ขอให้ท่านติดต่อสถาบันการเงิน<br>ที่ท่านมีปัญหา เพื่อแก้ไขให้สถานะให้เป็นปกติ<br>หรือปิดบัญชีให้เรียบร้อย<br>ก่อนสมัครสินเชื่อกับเรา ขอบคุณค่ะ',
            showConfirmButton: true,
            confirmButtonText: 'ตกลง',
            backdrop: false,
            customClass: {
                container: 'custom-swal-container',
                htmlContainer: 'custom-swal-html',
                confirmButton: 'custom-confirm-button',
            },
            willOpen: () => {
                const modal = Swal.getPopup();
                modal.style.borderRadius = '10px';
            },
        }).then(() => {
            window.location.href = '/'
        })
    }

    const handleSubmit = () => {
        if (values.ncb_status !== null) {
            localStorage.setItem('ncb_status', values.ncb_status)
            console.log('values prescreen', values)

            if (values.ncb_status === 'ปัจจุบันค้างชำระ') handleError()
            else {
                window.location.href = '/form'
            }
        } else {
            alert('กรุณาเลือกข้อมูลเครคิตบูโรปัจจุบันของท่าน')
        }
    }

    return (
        <div>
            <div style={{ backgroundColor: 'rgba(2,162,79,0.1)', height: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div className='content-header'>
                    ข้อมูลเครดิตบูโรปัจจุบันของท่านเป็นอย่างไร?
                </div>
            </div>

            <div style={{ padding: '0 30px', textAlign: 'left' }}>
                <Form style={{ margin: '3rem 0 3rem 25%' }}>
                    <Form.Check
                        label={<div className='content-2' style={{ color: 'rgba(36, 62, 123, 1)' }}>&nbsp;ปัจจุบันค้างชำระ</div>}
                        name="ncb_status"
                        type="radio"
                        id={`inline-radio-1`}
                        className="custom-radio-dot mb-4"
                        value={'ปัจจุบันค้างชำระ'}
                        onChange={(e) => handleRadioChange(e)}
                    />
                    <Form.Check
                        label={<div className='content-2' style={{ color: 'rgba(36, 62, 123, 1)' }}>&nbsp;ไม่เคยกู้</div>}
                        name="ncb_status"
                        type="radio"
                        id={`inline-radio-2`}
                        className="custom-radio-dot mb-4"
                        value={'ไม่เคยกู้ ไม่เคยมีสินเชื่อ'}
                        onChange={(e) => handleRadioChange(e)}
                    />
                    <Form.Check
                        label={<div className='content-2' style={{ color: 'rgba(36, 62, 123, 1)' }}>&nbsp;ไม่เคยค้างชำระ</div>}
                        name="ncb_status"
                        type="radio"
                        id={`inline-radio-2`}
                        className="custom-radio-dot mb-4"
                        value={'ไม่เคยค้างชำระ'}
                        onChange={(e) => handleRadioChange(e)}
                    />
                    <Form.Check
                        label={<div className='content-2' style={{ color: 'rgba(36, 62, 123, 1)' }}>&nbsp;เคยค้างชำระแก้ไขแล้ว</div>}
                        name="ncb_status"
                        type="radio"
                        id={`inline-radio-2`}
                        className="custom-radio-dot mb-4"
                        value={'เคยค้างชำระ แต่ปัจจุบันผ่อนชำระอยู่หรือปิดบัญชีแล้ว'}
                        onChange={(e) => handleRadioChange(e)}
                    />

                </Form>

                <button className="custom-button" onClick={() => handleSubmit()}>ตกลง</button>

                <img src={EzCal} alt='' style={{ width: '320px' }} className='center mt-5' />

            </div>
        </div>
    )
}

export default MicroSite
