import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

import { LiffProvider } from 'react-liff';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';
import Home from './pages/home';
import OTP from './pages/otp';
import Form from './pages/register/form';
import Signature from './pages/register/signature';
import Upload from './pages/upload';
import Summary from './pages/summary';
import Success from './pages/new_upload_success';
import Unsuccess from './pages/new_upload_unsuccess';
import Register from './pages/direct-debit/register';
import Consent from './pages/direct-debit/consent';
import End from './pages/direct-debit/end';
import PageNotFound from './pages/404';
import ServerError from './pages/400';
import BadGateway from './pages/502';
import Accepted from './pages/202';
import BankRegister from './pages/direct-debit/bank-register';
import Gateway from './pages/gateway';
import MicroSite from './pages/prescreen';
import RegisterLine from './pages/register_line';
import RegisterLineSuccess from './pages/register_line_success';

const brand = localStorage.getItem("brand");

if (brand === 'CASHKRUB') {
  document.documentElement.style.setProperty("--body-background-color", "#4d4d4d")
  document.documentElement.style.setProperty("--btn-select-md-background-color", "rgb(1 192 171)")
  document.documentElement.style.setProperty("--btn-select-md-border", "2px solid rgb(1 192 171)")
  document.documentElement.style.setProperty("--custom-button-2-background-color", "#fff")
  document.documentElement.style.setProperty("--herder-subject", "rgb(1 192 171)")
  document.documentElement.style.setProperty("--herder-subject-background-color", "rgb(1 192 171)")
  document.documentElement.style.setProperty("--my-screen-background-color", "#4d4d4d")
  document.documentElement.style.setProperty("--logo-width", "210px")
  document.documentElement.style.setProperty("--logo-margin", "0px")
  document.documentElement.style.setProperty("--custom-radio-dot-background-color", "rgb(1 192 171)")
  document.documentElement.style.setProperty("--custom-radio-dot-border-color", "rgb(1 192 171)")
  document.documentElement.style.setProperty("--custom-radio-dot-box-shadow", "0 0 0 0.2rem rgba(1, 192, 171, 0.2)")
  document.documentElement.style.setProperty("--custom-button-signature-background-color", "rgb(1 192 171)")
  document.documentElement.style.setProperty("--modal-content-background-color", "#4d4d4d")
}

const MASTER_LIFFID = '1657394869-ZJRr29vl'
const liffId2 = '1657394869-y7LX58ax'

let currentLiffId = MASTER_LIFFID;

if (window.location.pathname === '/line/register') {
  currentLiffId = liffId2;
}

ReactDOM.render(
  <LiffProvider liffId={currentLiffId} stubEnabled={false}>
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<App />} />
          <Route path="/home" element={<Home />} />
          <Route path="/otp" element={<OTP />} />
          <Route path="/form" element={<Form />} />
          <Route path="/signature" element={<Signature />} />
          <Route path="/upload" element={<Upload />} />
          <Route path="/summary" element={<Summary />} />
          <Route path="/success" element={<Success />} />
          <Route path="/unsuccess" element={<Unsuccess />} />
          <Route path="/gateway/:bank/:id" element={<Gateway />} />
          <Route path="/prescreen" element={<MicroSite />} />
          <Route path="/line/register" element={<RegisterLine />} />
          <Route path="/line/register/success" element={<RegisterLineSuccess />} />

          {/* direct-debit */}
          <Route path="/direct_debit/register" element={<Register />} />
          <Route path="/direct_debit/consent" element={<Consent />} />
          <Route path="/direct_debit/end" element={<End />} />
          <Route path="/direct_debit/bank-register" element={<BankRegister />} />

          <Route path="502" element={<BadGateway />} />
          <Route path="400" element={<ServerError />} />
          <Route path="202" element={<Accepted />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </BrowserRouter>
    </Provider>
  </LiffProvider>,
  document.getElementById('root')
)

