import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { useLiff } from 'react-liff';
import LogoPico from './../assets/images/Logo-Pico-เงินด่วน.png';
// import LogoCashkrub from './../assets/images/CASH-KRUB-LOGO-02-white.png';
import * as userHttpServices from '../services/digital-app-services';
import { validEmail } from '../assets/regex';

const RegisterLine = () => {
    const { error, liff, isLoggedIn, ready } = useLiff();
    const [lineAccessToken, setLineAccessToken] = useState()
    const [values, setValues] = useState({
        Fname: '',
        Lname: '',
        id_card_no: '',
        phone: '',
        email: '',
        type: 'SC'
    })
    const [isError, setError] = useState()

    const handleClick = async () => {
        const hasErrors = validateForm();
        console.log('hasErrors', hasErrors)

        if (hasErrors) {
            return
        }

        const body = { ...values }

        try {
            const res = await userHttpServices.newLineRegister(body, lineAccessToken);
            console.log('newLineRegister response:', res);
            if (res.data.success) {
                window.location.href = '/line/register/success'
            }
        } catch (error) {
            console.error("Error in newLineRegister:", error);
            alert('An error occurred during registration. Please try again.');
        }
    };

    const validateForm = () => {
        console.log('values', values)
        console.log('valies', values.id_card_no.length)
        // Your validation logic goes here
        const errors = {
            Fname: values.Fname === '',
            Lname: values.Lname === '',
            id_card_no: values.id_card_no.length < 13,
            phone: values.id_card_no.length < 9,
            email: !validEmail.test(values.email),
            // : values.Fname === '',

            // Add more validation rules as needed
        };

        console.log('err', errors)
        setError({ err: errors });

        // Check if any field has errors
        return Object.values(errors).some((error) => error);
    };


    const handleChange = (event) => {
        const { name, value } = event.target

        setValues((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    }

    const [isTallScreen, setIsTallScreen] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsTallScreen(window.innerHeight > 650);
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (!isLoggedIn) return;

        (async () => {
            const lineAccessToken = liff.getAccessToken()
            setLineAccessToken(lineAccessToken)
            console.log('lineAccessToken', lineAccessToken)
        })();

    }, [liff, isLoggedIn]);

    // const brand = localStorage.getItem("brand");
    // const logoSource = brand === 'CASHKRUB' ? LogoCashkrub : LogoMscw;

    const formatIDCardNumber = (input) => {
        // Remove non-numeric characters
        const numericInput = input.replace(/\D/g, '');

        const maxLength = 13;
        const truncatedInput = numericInput.slice(0, maxLength);
        // Check if the input is not empty
        if (truncatedInput.length > 0) {
            let formattedInput = '';

            if (truncatedInput.length <= 2) {
                formattedInput = truncatedInput.replace(/^(\d{1})(\d{1})$/, '$1-$2');
            } else if (truncatedInput.length <= 5) {
                formattedInput = truncatedInput.replace(/^(\d{1})(\d{1,4})$/, '$1-$2');
            } else if (truncatedInput.length <= 10) {
                formattedInput = truncatedInput.replace(/^(\d{1})(\d{4})(\d{1,5})$/, '$1-$2-$3');
            } else if (truncatedInput.length <= 12) {
                formattedInput = truncatedInput.replace(/^(\d{1})(\d{4})(\d{5})(\d{1,2})$/, '$1-$2-$3-$4');
            } else {
                formattedInput = truncatedInput.replace(/^(\d{1})(\d{4})(\d{5})(\d{2})(\d{1})$/, '$1-$2-$3-$4-$5');
            }

            return formattedInput;
        }

        // Return an empty string if the input is empty
        return '';
    };

    const showLoginButton = () => {
        if (error) return <p>Something is wrong.</p>;
        if (!ready) return <p>Loading...</p>;

        if (!isLoggedIn) {
            console.log(isLoggedIn)
            return (
                <>
                    <br />
                    <Button variant="select-md" onClick={liff.login}>
                        เข้าสู่ระบบ
                    </Button>
                </>
            );
        } else {
            // return (
            // <>
            // <br />
            // <Button variant="select-md" onClick={liff.login}>
            // เข้าสู่ระบบใหม่
            // </Button>
            // </>
            // )
        }
    };

    if (!isLoggedIn) {
        console.log(isLoggedIn)
        return (
            <div className="center" style={{ maxWidth: '30rem' }}>
                <div className="center" style={{ paddingTop: '50px' }}>
                    <img src={LogoPico} alt="" style={{ width: '250px', margin: '30px' }} />
                </div>

                <br />
                {showLoginButton()}
            </div>
        )
    } else { // Login success
        return (
            <div className='center' style={{
                maxWidth: '30rem',
                minHeight: '100vh',
                boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px'
            }}>
                <div className="center" style={{ maxWidth: '30rem' }}>
                    <div style={{ padding: '45px' }}>
                        <Row>
                            <Col xs="12" style={{ padding: 5 }}>
                                <Form.Group className="mb-2">
                                    <Form.Control
                                        size="md"
                                        placeholder="ชื่อ"
                                        type="text"
                                        name="Fname"
                                        value={values.Fname}
                                        onChange={handleChange}
                                        isInvalid={values.Fname === '' && isError?.err?.Fname}
                                    />
                                    <Form.Control.Feedback type="invalid" >
                                        {values.Fname === '' && 'กรุณากรอกชื่อให้ครบถ้วน'}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            <Col xs="12" style={{ padding: 5 }}>
                                <Form.Group className="mb-2">
                                    <Form.Control
                                        size="md"
                                        placeholder="นามสกุล"
                                        type="text"
                                        name="Lname"
                                        value={values.Lname}
                                        onChange={handleChange}
                                        isInvalid={values.Lname === '' && isError?.err?.Lname}
                                    />
                                    <Form.Control.Feedback type="invalid" >
                                        {values.Lname === '' && 'กรุณากรอกนามสกุลให้ครบถ้วน'}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            <Col xs="12" style={{ padding: 5 }}>
                                <Form.Group className="mb-2">
                                    <Form.Control
                                        size="md"
                                        placeholder="เลขบัตรประชาชน"
                                        type="text"  // Change type to text to allow for non-numeric characters
                                        name="id_card_no"
                                        value={formatIDCardNumber(values.id_card_no)}
                                        onChange={(e) => {
                                            const inputValue = e.target.value;
                                            const cleanedInput = inputValue.replace(/[^0-9]/g, '');
                                            const MAX_INPUT = 13

                                            if (cleanedInput.length <= MAX_INPUT) {
                                                handleChange({ target: { name: 'id_card_no', value: cleanedInput } });
                                            }
                                        }}
                                        isInvalid={values.id_card_no.length < 13 && isError?.err?.id_card_no}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {(values.id_card_no === '' && 'กรุณากรอกเลขบัตรประชาชนให้ครบถ้วน')
                                            || (values.id_card_no.length < 13 && 'กรุณากรอกเลขบัตรประชาชนให้ถูกต้อง')}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            <Col xs="12" style={{ padding: 5 }}>
                                <Form.Group className="mb-2">
                                    <Form.Control
                                        size="md"
                                        placeholder="เบอร์โทรศัพท์"
                                        type="tel"
                                        name="phone"
                                        value={values.phone}
                                        onChange={(e) => {
                                            const inputValue = e.target.value;
                                            const cleanedInput = inputValue.replace(/[^0-9]/g, '');
                                            const MAX_INPUT = 10

                                            if (cleanedInput.length <= MAX_INPUT) {
                                                handleChange({ target: { name: 'phone', value: cleanedInput } });
                                            }
                                        }}
                                        isInvalid={values.phone.length < 9 && isError?.err?.phone}
                                    />
                                    <Form.Control.Feedback type="invalid" >
                                        {(values.phone === '' && 'กรุณากรอกเบอร์โทรศัพท์ให้ครบถ้วน')
                                            || (values.phone.length < 9 && 'กรุณากรอกเบอร์โทรศัพท์ให้ถูกต้อง')}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            <Col xs="12" style={{ padding: 5 }}>
                                <Form.Group className="mb-2">
                                    <Form.Control
                                        size="md"
                                        placeholder="อีเมล"
                                        type="email"
                                        name="email"
                                        value={values.email}
                                        onChange={handleChange}
                                        isInvalid={!validEmail.test(values.email) && isError?.err?.email}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {(values.email === '' && 'กรุณากรอกอีเมลให้ครบถ้วน')
                                            || (!validEmail.test(values.email) && 'กรุณากรอกอีเมลให้ถูกต้อง')}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>

                        <br />
                        <div className={isTallScreen ? 'button-test' : ''}>
                            <p style={{ color: 'whtie', fontSize: '12px' }}>กรุณากรอกข้อมูลให้ครบถ้วนเพื่อลงทะเบียน<br />สำหรับขอรหัสการส่งข้อความไลน์</p>
                            <Row className='center'>
                                <Col xs="12" style={{ padding: 10 }}>
                                    <Button variant="select-md" onClick={() => handleClick()}>ลงทะเบียน</Button>
                                </Col>
                            </Row>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}

export default RegisterLine
