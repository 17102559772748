import React from 'react'
import { Col, Row, Button, Form, Stack } from 'react-bootstrap';
import Banks from '../../datas/banks.json'
import IconScb from '../../../src/assets/images/icon_bank_scb.png'
import IconKbank from '../../../src/assets/images/icon_bank_kbank.png'
import IconKtb from '../../../src/assets/images/icon_bank_ktb.png'
import IconAeon from '../../../src/assets/images/icon_bank_aeon.png'
import IconElse from '../../../src/assets/images/icon_bank_else.png'

const FormEditor5 = ({ isError, setIsError, values, setValues, setActiveStep,validateForm }) => {

    const handleSubmit = (event) => {
        event.preventDefault();

        const hasErrorValue = validateForm()
        if(hasErrorValue) return

        setActiveStep((prevActiveStep) => ({ current: prevActiveStep.current + 1, top: 6 }));
    };

    const handleChange = (event) => {
        const { name, value } = event.target

        setValues((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    }

    return (
        <div>
            <Form onSubmit={handleSubmit}>
                <Form.Group>
                    <p style={{ fontWeight: 300, fontSize: 16, textAlign: 'left' }}>ข้อมูลการสมัครสินเชื่อ</p>
                    <Row>
                        <Col xs="12" style={{ padding: 5 }}>
                            <Form.Group className="mb-2">
                                <Form.Control
                                    size="md"
                                    placeholder="ภาระหนี้รวมทั้งหมด"
                                    type="number"
                                    name="totalDebt"
                                    value={values.totalDebt}
                                    onChange={handleChange}
                                    isInvalid={isError.err?.totalDebt}
                                />
                                <Form.Control.Feedback type="invalid" >
                                    {isError.message?.totalDebt}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col xs="12" style={{ padding: 5 }}>
                            <Form.Group className="mb-2">
                                <Form.Control
                                    size="md"
                                    placeholder="ผ่อนชำระต่อเดือน"
                                    type="number"
                                    name="monthlyInstallments"
                                    value={values.monthlyInstallments}
                                    onChange={handleChange}
                                    isInvalid={isError.err?.monthlyInstallments}
                                />
                                <Form.Control.Feedback type="invalid" >
                                    {isError.message?.monthlyInstallments}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Stack className="mb-2" direction="horizontal" gap={1}>
                        <p style={{ fontWeight: 300, fontSize: 16, marginTop: 15, marginRight: 5 }}>สถาบันการเงิน</p>
                        <img src={IconScb} alt="IconScb" style={{ width: '33px', height: '33px' }} />
                        <img src={IconKbank} alt="IconKbank" style={{ width: '33px', height: '33px' }} />
                        <img src={IconKtb} alt="IconKtb" style={{ width: '33px', height: '33px' }} />
                        <img src={IconAeon} alt="IconAeon" style={{ width: '33px', height: '33px' }} />
                        <img src={IconElse} alt="IconElse" style={{ width: '33px', height: '33px' }} />
                    </Stack>
                    <p style={{ fontWeight: 300, fontSize: 16, textAlign: 'left' }}>วัตถุประสงค์ในการขอสินเชื่อ</p>
                    <Row>
                        <Col xs="12" style={{ padding: 5 }}>
                            <Form.Group className="mb-2">
                                <Form.Control
                                    size="md"
                                    placeholder="จำนวนเงินที่ขอกู้"
                                    type="number"
                                    name="amountReq"
                                    value={values.amountReq}
                                    onChange={handleChange}
                                    isInvalid={isError.err?.amountReq}
                                    disabled={values.haveData}
                                />
                                <Form.Control.Feedback type="invalid" >
                                    {isError.message?.amountReq}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col xs="12" style={{ padding: 5 }}>
                            <Form.Group className="mb-2">
                                <Form.Control
                                    size="md"
                                    placeholder="ยอดที่ต้องการผ่อนชำระต่อเดือน"
                                    type="number"
                                    name="payAble"
                                    value={values.payAble}
                                    onChange={handleChange}
                                    isInvalid={isError.err?.payAble}
                                />
                                <Form.Control.Feedback type="invalid" >
                                    {isError.message?.payAble}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col xs="12" style={{ padding: 5 }}>
                            <Form.Group className="mb-2">
                                <Form.Control
                                    size="md"
                                    placeholder="วัตถุประสงค์"
                                    type="text"
                                    name="objective"
                                    value={values.objective}
                                    onChange={handleChange}
                                    isInvalid={isError.err?.objective}
                                />
                                <Form.Control.Feedback type="invalid" >
                                    {isError.message?.objective}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>

                    <p style={{ fontWeight: 300, fontSize: 16, textAlign: 'left' }}>วิธีการรับเงิน</p>
                    <Row>
                        <Col xs="12" style={{ padding: 5 }}>
                            <Form.Group className="mb-2">
                                <Form.Control
                                    as="select"
                                    size="md"
                                    placeholder="ธนาคาร"
                                    type="text"
                                    name="bank"
                                    value={values.bank}
                                    onChange={handleChange}
                                    isInvalid={isError.err?.bank}
                                    style={{ color: values.bank === "" ? '#6d757d' : '' }}
                                disabled={values.haveData}
                                >
                                    <option value="" defaultValue disabled>ธนาคาร</option>
                                    {
                                        Array.from(Banks.banks, (item, index) => {
                                            return <option key={index} value={item}>{item}</option>
                                        })
                                    }
                                </Form.Control>
                                <Form.Control.Feedback type="invalid" >
                                    {isError.message?.bank}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col xs="12" style={{ padding: 5 }}>
                            <Form.Group className="mb-2">
                                <Form.Control
                                    size="md"
                                    placeholder="เลขที่บัญชี"
                                    type="number"
                                    name="account"
                                    value={values.account}
                                    onChange={handleChange}
                                    isInvalid={isError.err?.account}
                                    disabled={values.haveData}
                                />
                                <Form.Control.Feedback type="invalid" >
                                    {isError.message?.account}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    <div className="mt-5">
                        <Row>
                            <Button variant="secondary" type="submit" style={{ borderRadius: '10px' }}>ต่อไป</Button>
                            <Button variant="danger" style={{ borderRadius: '10px', marginTop: '20px', display: values.haveData ? '':'none' }} onClick={() => window.history.back()}>ยกเลิกแก้ไขข้อมูล</Button>
                        </Row>
                    </div>
                </Form.Group>
            </Form>
        </div>
    )
}

export default FormEditor5
