import React from 'react'
import { Button, Row } from 'react-bootstrap';
import { BsFillCircleFill, BsFillCheckCircleFill, BsXCircleFill } from "react-icons/bs";

import * as userHttpServices from '../../services/digital-app-services';
import { useNavigate } from 'react-router-dom';

const Page1 = ({
    data,
    upload,
    setUpload,
    count,
    type,
    setType,
    setStep
}) => {
    const navigate = useNavigate();
    // const [isLoading, setIsLoading] = useState(true)

    const handleClick = (name) => {
        console.log('name', name)
        // localStorage.setItem("type", ft);
        setType(name)
        setStep((prev) => prev + 1);
    };

    const mainStatus = (status, top, right) => {
        if (status === 1) {
            return <BsFillCheckCircleFill
                className="astext"
                fontSize={25}
                style={{
                    top: top,
                    right: right,
                    color: '#a9abad',
                    background: '#292d31',
                    borderRadius: '50%',
                }}
            />
        } else if (status === 2) {
            return <BsXCircleFill className="status2-icon astext" fontSize={25} style={{ top: top, right: right, }} />
        } else if (status === 3) {
            return <BsFillCheckCircleFill className="status3-icon astext" fontSize={25} style={{ top: top, right: right, }} />
        } else {
            return <BsFillCircleFill className="status0-icon astext" fontSize={25} style={{ top: top, right: right, }} />
        }
    }

    const handleUpload = async () => {
        let success = true;
        let failCounter = 0
        console.log('Upload', upload);

        for (const val of Object.values(upload)) {
            if (!Boolean(val.data.url)) {
                // New upload
                console.log('New upload data:', val);

                for (const formData of val.data) {
                    console.log('FormData:', formData);
                }

                try {
                    const res = await userHttpServices.uploadfile(val.data);
                    console.log('Upload response:', res);
                } catch (error) {
                    console.error('Upload error:', error);
                    success = false;
                    failCounter++
                } finally {
                    setUpload([]);
                }
            } else {
                // Old upload
            }
        }

        if (success) {
            console.log('success')
            navigate('/success', { state: {} });
        } else {
            console.log('fail', failCounter)
            navigate('/unsuccess', { state: { counter: failCounter } });
        }
    }

    return (
        <div className='center' style={{ maxWidth: '30rem' }}>
            <div style={{ padding: '45px' }}>
                <div className="header-subject" style={{ borderRadius: '30px' }}>
                    <p className="p-2" style={{ fontWeight: 400, fontSize: 18, textAlign: 'center' }}>
                        อัปโหลดเอกสาร<br />เพื่อการพิจารณาวงเงินสินเชื่อ
                    </p>
                </div>

                <div>
                    <Row className="pt-3">
                        <button
                            className='custom-button-2'
                            variant="doc"
                            onClick={() => handleClick('id_card_no')}
                            id="id_card_no"
                            disabled={''}
                            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative' }}
                        >
                            <span style={{ textAlign: 'center' }}>สำเนาบัตรประชาชน</span>
                            <span style={{ position: 'right' }}>
                                {mainStatus(data.detail[0].id_card_status, 6, 6)}
                            </span>
                        </button>
                    </Row>

                    <Row className="pt-3">
                        <button
                            className='custom-button-2'
                            variant="doc"
                            onClick={() => handleClick('statement')}
                            id="statement"
                            disabled={''}
                            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative' }}
                        >
                            <span style={{ textAlign: 'center' }}>รายการเดินบัญชีธนาคาร<br />ย้อนหลัง 6 เดือน</span>
                            <span style={{ position: 'right' }}>
                                {mainStatus(data.detail[0].statement_status, 17, 6)}
                            </span>
                        </button>
                    </Row>

                    <Row className="pt-3">
                        <button
                            className='custom-button-2'
                            variant="doc"
                            onClick={() => handleClick('salary')}
                            id="salary"
                            disabled={''}
                            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative' }}
                        >
                            <span style={{ textAlign: 'center' }}>สลิปเงินเดือน/หนังสือรับรองรายได้</span>
                            <span style={{ position: 'right' }}>
                                {mainStatus(data.detail[0].salary_status, 6, 6)}
                            </span>
                        </button>
                    </Row>

                    <Row className="pt-3">
                        <button
                            className='custom-button-2'
                            variant="doc"
                            onClick={() => handleClick('house_regis')}
                            id="house_regis"
                            disabled={''}
                            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative' }}
                        >
                            <span style={{ textAlign: 'center' }}>สำเนาทะเบียนบ้าน</span>
                            <span style={{ position: 'right' }}>
                                {mainStatus(data.detail[0].house_regis_status, 6, 6)}
                            </span>
                        </button>
                    </Row>

                    <Row className="pt-3">
                        <button
                            className='custom-button-2'
                            variant="doc"
                            onClick={() => handleClick('book_bank')}
                            id="book_bank"
                            disabled={''}
                            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative' }}
                        >
                            <span style={{ textAlign: 'center' }}>หน้าสมุดบัญชีธนาคาร</span>
                            <span style={{ position: 'right' }}>
                                {mainStatus(data.detail[0].book_bank_status, 6, 6)}
                            </span>
                        </button>
                    </Row>

                    <Row className="pt-3">
                        <button
                            className='custom-button-2'
                            variant="doc"
                            onClick={() => handleClick('auth')}
                            id="auth"
                            disabled={''}
                            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative' }}
                        >
                            <span style={{ textAlign: 'center' }}>รูปถ่ายคู่บัตรประชาชน</span>
                            <span style={{ position: 'right' }}>
                                {mainStatus(data.detail[0].auth_status, 6, 6)}
                            </span>
                        </button>
                    </Row>

                    <Row className="pt-3">
                        <button
                            className='custom-button-2'
                            variant="doc"
                            onClick={() => handleClick('other')}
                            id="other"
                            disabled={''}
                            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative' }}
                        >
                            <span style={{ textAlign: 'center' }}>เอกสารอื่นๆ</span>
                            <span style={{ position: 'right' }}>
                                {mainStatus(data.detail[0].orther_file_status, 6, 6)}
                            </span>
                        </button>
                    </Row>
                </div>

                <br />
                <p style={{ fontSize: 14, color: '#cccccc', textAlign: 'left' }} onClick={() => { window.location.href = '/form' }}>*แก้ไขข้อมูล*</p>

                <p style={{ textAlign: 'left', fontWeight: '20px' }}>
                    *ไฟล์ : JPEG/JPG/PNG/PDF
                    <br />ขนาดไม่เกิน 10MB
                </p>
                <br />

                <div>
                    <Row >
                        <Button variant="secondary" onClick={() => { handleUpload(); console.log('count file upload:', count) }} disabled={count === 0 ? true : false}>อัปโหลด{count > 0 ? ` ${count} ไฟล์` : ''}</Button>
                    </Row>
                </div>
            </div>
        </div>
    )
}

export default Page1
