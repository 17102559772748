import Fail from './../assets/images/2.png'
import LogoPico from './../assets/images/Logo-Pico-เงินด่วน.png';
// import LogoCashkrub from './../assets/images/CASH-KRUB-LOGO-02-white.png';

export default function PageNotFound() {
    // const brand = localStorage.getItem("brand");
    // const logoSource = brand === 'CASHKRUB' ? LogoCashkrub : LogoMscw;

    return (
        <div className="center" style={{ maxWidth: '30rem' }}>
            <div className="center" style={{ paddingTop: '50px' }}>
                <img src={LogoPico} alt="" style={{ width: '250px', margin: '30px' }} />
            </div>

            <div style={{ padding: '40px' }}>
                <img className='mb-4' src={Fail} style={{ width: '80px' }} alt="fail" />

                <h1>Bad Gateway</h1>
                <p>ไม่สามารถเข้าถึงลิงก์นี้ได้เนื่องจาก <br /> หมดเวลาในการเข้าถึงกรุณาติดต่อเจ้าหน้าที่</p>

                <br />
                <br />
                <br />

                <a href="/" style={{ color: '#fff' }}>Go back to homepage</a>

            </div>
        </div>

    );
}