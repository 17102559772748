import React, { useEffect, useState } from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import * as userHttpServices from '../services/digital-app-services';
import Success from '../assets/images/1.png';
import Fail from '../assets/images/2.png';
import Pending from '../assets/images/3.png';
import LogoPico from './../assets/images/Logo-Pico-เงินด่วน.png';
// import LogoCashkrub from './../assets/images/CASH-KRUB-LOGO-02-white.png';
import Cookies from 'js-cookie';

function Summary() {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const status = state?.status
  const [data, setData] = useState()

  console.log('state', state)

  // const brand = localStorage.getItem("brand");
  // const logoSource = brand === 'CASHKRUB' ? LogoCashkrub : LogoMscw;

  useEffect(() => {
    const lineAccessToken = Cookies.get('lineAccessToken');
    console.log('lineAccessToken', lineAccessToken)

    const handleLineLogin = async () => {
      try {
        const res = await userHttpServices.lineLogin(lineAccessToken);
        console.log('res>>>', res)

        setData(res.data.data);

      } catch (error) {
        console.error("Error:", error);
        alert('[HRL36] Error has occurred:', lineAccessToken);
      }
    };

    handleLineLogin();
  }, []);

  const handleClickNewContact = () => {
    const { contact_id, user_id } = data?.detail[0];
    const obj = { contact_id, user_id };

    console.log(obj);

    userHttpServices.updateUserToCancle(obj).then((res) => {
      console.log(res);
      if (res.data.success) {
        console.log('success');
        navigate('/home');
      } else {
        console.log('err');
      }
    });
  };

  return (
    <div className="center" style={{ maxWidth: '30rem' }}>
      <div className="center" style={{ paddingTop: '50px' }}>
        <img src={LogoPico} alt="" style={{ width: '250px', margin: '30px' }} />
      </div>

      <div style={{ padding: '40px' }}>
        <p className="p-2" style={{ fontWeight: 400, fontSize: '18px' }}>สถานะการสมัครของคุณ</p>

        <Row>
          <Col xs={4} style={{ marginRight: '-1rem' }}>
            <img src={Success} alt="" style={{ width: '40px' }} />
          </Col>
          <Col sx={8} className="center" style={{ textAlign: 'left' }}>
            <p style={{ fontSize: '16px', fontWeight: 400 }}>เอกสารครบแล้ว</p>
          </Col>
        </Row>
        <Row>
          <Col xs={4}>
            <p style={{ fontSize: 30, marginTop: '15px' }}>|</p>
          </Col>
        </Row>

        <Row>
          <Col xs={4} style={{ marginRight: '-1rem' }}>
            <img src=
              {status === 3
                ? Success
                : status === 4
                  ? Fail
                  : Pending
              }
              alt="status"
              width="60"
            />
          </Col>
          <Col sx={8} className="center" style={{ backgroundColor: '', textAlign: 'left' }}>
            <h4>
              {status === 3
                ? 'ขอแสดงความยินดี'
                : status === 4
                  ? 'ขอแสดงความเสียใจ'
                  : 'อยู่ในขั้นตอนพิจารณา'
              }
            </h4>
            <p>
              {status === 3
                ? 'คุณได้ผ่านการอนุมัติสินเชื่อ'
                : status === 4
                  ? 'คุณไม่ได้รับการอนุมัติสินเชื่อ'
                  : 'กรุณารอพิจารณา 1-3 วัน'
              }
            </p>
          </Col>
        </Row>

        <div className='mt-5 center'>
          <Row>
            <Button variant="select-md" onClick={handleClickNewContact} style={{ display: status === 4 ? 'block' : 'none' }}>
              สมัครสินเชื่อใหม่
            </Button>
          </Row>

          <Row>
            <Button
              variant="secondary"
              onClick={() => navigate('/upload', { state: { accStatus: 1 } })}
              style={{ display: status !== 4 ? 'block' : 'none' }}
            >
              อัพโหลดเอกสารเพิ่มเติม
            </Button>
          </Row>
        </div>
      </div>
    </div>
  );
}

export default Summary;