import React, { useState } from 'react'
import { Col, Row, Button, Form } from 'react-bootstrap';
import Consent1 from '../model/model_personal'
import Consent2 from '../model/model_installment'
import Consent3 from '../model/model_loan'
import Consent4 from '../model/model_information'
import Consent5 from '../model/model_pdpa'
import { BiSolidMessageSquareEdit } from "react-icons/bi";
import configMscw from '../../config/mscw.json';
import configCashkrub from '../../config/cashkrub.json';

const FormSummary = ({
    setActiveStep,
    isError,
    setIsError,
    values,
    setValues,
    handleRegister
}) => {

    const [showModal1, setShowModal1] = useState(false)
    const handleCloseModal1 = () => setShowModal1(false)
    const handleShowModal1 = () => setShowModal1(true)

    const [showModal2, setShowModal2] = useState(false)
    const handleCloseModal2 = () => setShowModal2(false)
    const handleShowModal2 = () => setShowModal2(true)

    const [showModal3, setShowModal3] = useState(false)
    const handleCloseModal3 = () => setShowModal3(false)
    const handleShowModal3 = () => setShowModal3(true)

    const [showModal4, setShowModal4] = useState(false)
    const handleCloseModal4 = () => setShowModal4(false)
    const handleShowModal4 = () => setShowModal4(true)

    const [showModal5, setShowModal5] = useState(false)
    const handleCloseModal5 = () => setShowModal5(false)
    const handleShowModal5 = () => setShowModal5(true)

    const brand = localStorage.getItem('brand')
    const configSource = brand === 'CASHKRUB' ? configCashkrub.step_progress.icon_color : configMscw.step_progress.icon_color;

    return (
        <div>
            <p style={{ fontWeight: 300, fontSize: 16, textAlign: 'left' }}>ยืนยันข้อมูลอีกครั้ง</p>
            <div className="mb-3">
                <Row style={{ backgroundColor: '#fff', borderRadius: '10px', padding: '5px' }}>
                    <Col xs="12" >
                        <div className="d-flex justify-content-between mt-2">
                            <p style={{ fontSize: 16, color: 'black' }}>ข้อมูลส่วนตัว</p>
                            <BiSolidMessageSquareEdit size={24} color={configSource} onClick={() => setActiveStep((prevActiveStep) => ({ ...prevActiveStep, current: 1 }))} />
                        </div>
                    </Col>
                    <Col xs="12" style={{ marginBottom: '-10px' }}>
                        <div className="d-flex justify-content-between">
                            <p style={{ fontWeight: 300, fontSize: 14, color: 'black' }}>ชื่อ-นามสกุล</p>
                            <p style={{ fontWeight: 300, fontSize: 14, color: 'black' }}>{values.gender} {values.firstName} {values.lastName}</p>
                        </div>
                    </Col>
                    <Col xs="12" style={{ marginBottom: '-10px' }}>
                        <div className="d-flex justify-content-between">
                            <p style={{ fontWeight: 300, fontSize: 14, color: 'black' }}>วันเกิดปีเกิด</p>
                            <p style={{ fontWeight: 300, fontSize: 14, color: 'black' }}>
                                {values.birthDate ? values.birthDate : '-'} / {values.birthMonth ? values.birthMonth : '-'} / {values.birthYear ? values.birthYear : '-'}
                            </p>
                        </div>
                    </Col>
                    <Col xs="12" style={{ marginBottom: '-10px' }}>
                        <div className="d-flex justify-content-between">
                            <p style={{ fontWeight: 300, fontSize: 14, color: 'black' }}>เลขบัตรประชาชน</p>
                            <p style={{ fontWeight: 300, fontSize: 14, color: 'black' }}>{values.identity}</p>
                        </div>
                    </Col>
                    <Col xs="12" style={{ marginBottom: '-10px' }}>
                        <div className="d-flex justify-content-between">
                            <p style={{ fontWeight: 300, fontSize: 14, color: 'black' }}>เบอร์โทร</p>
                            <p style={{ fontWeight: 300, fontSize: 14, color: 'black' }}>{values.phone}</p>
                        </div>
                    </Col>
                    <Col xs="12" style={{ marginBottom: '-10px' }}>
                        <div className="d-flex justify-content-between">
                            <p style={{ fontWeight: 300, fontSize: 14, color: 'black' }}>อีเมล์</p>
                            <p style={{ fontWeight: 300, fontSize: 14, color: 'black' }}>{values.line}</p>
                        </div>
                    </Col>
                    <Col xs="12" style={{ marginBottom: '-10px' }}>
                        <div className="d-flex justify-content-between">
                            <p style={{ fontWeight: 300, fontSize: 14, color: 'black' }}>Line</p>
                            <p style={{ fontWeight: 300, fontSize: 14, color: 'black' }}>{values.email}</p>
                        </div>
                    </Col>
                    <Col xs="12" style={{ marginBottom: '-10px' }}>
                        <div className="d-flex justify-content-between">
                            <p style={{ fontWeight: 300, fontSize: 14, color: 'black' }}>Facebook</p>
                            <p style={{ fontWeight: 300, fontSize: 14, color: 'black' }}>{values.facebook}</p>
                        </div>
                    </Col>
                </Row>
            </div>
            <div className="mb-3">
                <Row style={{ backgroundColor: '#fff', borderRadius: '10px', padding: '5px' }}>
                    <Col xs="12" >
                        <div className="d-flex justify-content-between mt-2">
                            <p style={{ fontSize: 16, color: 'black' }}>ที่อยู่ตามบัตรประชาชน</p>
                            <BiSolidMessageSquareEdit size={24} color={configSource} onClick={() => setActiveStep((prevActiveStep) => ({ ...prevActiveStep, current: 1 }))} />
                        </div>
                    </Col>

                    <Col xs="12" style={{ marginBottom: '-10px' }}>
                        <div className="d-flex justify-content-between">
                            <p style={{ fontWeight: 300, fontSize: 14, color: 'black' }}>ที่อยู่</p>
                            <p style={{ fontWeight: 300, fontSize: 14, color: 'black' }}>{values.address}</p>
                        </div>
                    </Col>
                    <Col xs="12" style={{ marginBottom: '-10px' }}>
                        <div className="d-flex justify-content-between">
                            <p style={{ fontWeight: 300, fontSize: 14, color: 'black' }}>แขวง/ตำบล</p>
                            <p style={{ fontWeight: 300, fontSize: 14, color: 'black' }}>{values.district}</p>
                        </div>
                    </Col>
                    <Col xs="12" style={{ marginBottom: '-10px' }}>
                        <div className="d-flex justify-content-between">
                            <p style={{ fontWeight: 300, fontSize: 14, color: 'black' }}>เขต/อำเภอ</p>
                            <p style={{ fontWeight: 300, fontSize: 14, color: 'black' }}>{values.county}</p>
                        </div>
                    </Col>
                    <Col xs="12" style={{ marginBottom: '-10px' }}>
                        <div className="d-flex justify-content-between">
                            <p style={{ fontWeight: 300, fontSize: 14, color: 'black' }}>จังหวัด</p>
                            <p style={{ fontWeight: 300, fontSize: 14, color: 'black' }}>{values.province}</p>
                        </div>
                    </Col>
                    <Col xs="12" style={{ marginBottom: '-10px' }}>
                        <div className="d-flex justify-content-between">
                            <p style={{ fontWeight: 300, fontSize: 14, color: 'black' }}>รหัสไปรษณีย์</p>
                            <p style={{ fontWeight: 300, fontSize: 14, color: 'black' }}>{values.zipcode}</p>
                        </div>
                    </Col>
                </Row>
            </div>
            <div className="mb-3">
                <Row style={{ backgroundColor: '#fff', borderRadius: '10px', padding: '5px' }}>
                    <Col xs="12" >
                        <div className="d-flex justify-content-between mt-2">
                            <p style={{ fontSize: 16, color: 'black' }}>ที่อยู่ปัจจุบัน</p>
                            <BiSolidMessageSquareEdit size={24} color={configSource} onClick={() => setActiveStep((prevActiveStep) => ({ ...prevActiveStep, current: 2 }))} />
                        </div>
                    </Col>
                    <Col xs="12" style={{ marginBottom: '-10px' }}>
                        <div className="d-flex justify-content-between">
                            <p style={{ fontWeight: 300, fontSize: 14, color: 'black' }}>ที่อยู่</p>
                            <p style={{ fontWeight: 300, fontSize: 14, color: 'black' }}>{values.currentAddress}</p>
                        </div>
                    </Col>
                    <Col xs="12" style={{ marginBottom: '-10px' }}>
                        <div className="d-flex justify-content-between">
                            <p style={{ fontWeight: 300, fontSize: 14, color: 'black' }}>แขวง/ตำบล</p>
                            <p style={{ fontWeight: 300, fontSize: 14, color: 'black' }}>{values.currentDistrict}</p>
                        </div>
                    </Col>
                    <Col xs="12" style={{ marginBottom: '-10px' }}>
                        <div className="d-flex justify-content-between">
                            <p style={{ fontWeight: 300, fontSize: 14, color: 'black' }}>เขต/อำเภอ</p>
                            <p style={{ fontWeight: 300, fontSize: 14, color: 'black' }}>{values.currentCounty}</p>
                        </div>
                    </Col>
                    <Col xs="12" style={{ marginBottom: '-10px' }}>
                        <div className="d-flex justify-content-between">
                            <p style={{ fontWeight: 300, fontSize: 14, color: 'black' }}>จังหวัด</p>
                            <p style={{ fontWeight: 300, fontSize: 14, color: 'black' }}>{values.currentProvince}</p>
                        </div>
                    </Col>
                    <Col xs="12" style={{ marginBottom: '-10px' }}>
                        <div className="d-flex justify-content-between">
                            <p style={{ fontWeight: 300, fontSize: 14, color: 'black' }}>รหัสไปรษณีย์</p>
                            <p style={{ fontWeight: 300, fontSize: 14, color: 'black' }}>{values.currentZipcode}</p>
                        </div>
                    </Col>
                </Row>
            </div>
            <div className="mb-3">
                <Row style={{ backgroundColor: '#fff', borderRadius: '10px', padding: '5px' }}>
                    <Col xs="12" >
                        <div className="d-flex justify-content-between mt-2">
                            <p style={{ fontSize: 16, color: 'black' }}>ที่อยู่ที่ทำงาน</p>
                            <BiSolidMessageSquareEdit size={24} color={configSource} onClick={() => setActiveStep((prevActiveStep) => ({ ...prevActiveStep, current: 3 }))} />
                        </div>
                    </Col>
                    <Col xs="12" style={{ marginBottom: '-10px' }}>
                        <div className="d-flex justify-content-between">
                            <p style={{ fontWeight: 300, fontSize: 14, color: 'black' }}>ที่อยู่</p>
                            <p style={{ fontWeight: 300, fontSize: 14, color: 'black' }}>{values.compAddress}</p>
                        </div>
                    </Col>
                    <Col xs="12" style={{ marginBottom: '-10px' }}>
                        <div className="d-flex justify-content-between">
                            <p style={{ fontWeight: 300, fontSize: 14, color: 'black' }}>แขวง/ตำบล</p>
                            <p style={{ fontWeight: 300, fontSize: 14, color: 'black' }}>{values.compDistrict}</p>
                        </div>
                    </Col>
                    <Col xs="12" style={{ marginBottom: '-10px' }}>
                        <div className="d-flex justify-content-between">
                            <p style={{ fontWeight: 300, fontSize: 14, color: 'black' }}>เขต/อำเภอ</p>
                            <p style={{ fontWeight: 300, fontSize: 14, color: 'black' }}>{values.compCounty}</p>
                        </div>
                    </Col>
                    <Col xs="12" style={{ marginBottom: '-10px' }}>
                        <div className="d-flex justify-content-between">
                            <p style={{ fontWeight: 300, fontSize: 14, color: 'black' }}>จังหวัด</p>
                            <p style={{ fontWeight: 300, fontSize: 14, color: 'black' }}>{values.compProvince}</p>
                        </div>
                    </Col>
                    <Col xs="12" style={{ marginBottom: '-10px' }}>
                        <div className="d-flex justify-content-between">
                            <p style={{ fontWeight: 300, fontSize: 14, color: 'black' }}>รหัสไปรษณีย์</p>
                            <p style={{ fontWeight: 300, fontSize: 14, color: 'black' }}>{values.compZipcode}</p>
                        </div>
                    </Col>
                </Row>
            </div>
            <div style={{ marginBottom: '3rem' }}>
                <Row style={{ backgroundColor: '#fff', borderRadius: '10px', padding: '5px' }}>
                    <Col xs="12" >
                        <div className="d-flex justify-content-between mt-2">
                            <p style={{ fontSize: 16, color: 'black' }}>ข้อมูลอื่นๆ</p>
                            <BiSolidMessageSquareEdit size={24} color={configSource} onClick={() => setActiveStep((prevActiveStep) => ({ ...prevActiveStep, current: 4 }))} />
                        </div>
                    </Col>
                    <Col xs="12" style={{ marginBottom: '-10px' }}>
                        <div className="d-flex justify-content-between">
                            <p style={{ fontWeight: 300, fontSize: 14, color: 'black' }}>สถานภาพ</p>
                            <p style={{ fontWeight: 300, fontSize: 14, color: 'black' }}>{values.status}</p>
                        </div>
                    </Col>
                    <Col xs="12" style={{ marginBottom: '-10px', display: values.status === 'สมรส' ? '' : 'none' }}>
                        <div className="d-flex justify-content-between">
                            <p style={{ fontWeight: 300, fontSize: 14, color: 'black' }}>ข้อมูลคู่สมรส</p>
                            <p style={{ fontWeight: 300, fontSize: 14, color: 'black' }}>{values.spouseName} {values.spouseSurname}</p>
                        </div>
                    </Col>
                    <Col xs="12" style={{ marginBottom: '10px' }}>
                        <div className="d-flex justify-content-between">
                            <p style={{ fontWeight: 300, fontSize: 14, color: 'black' }}>บุคคลที่ตามทวงหนี้ได้</p>
                            <p style={{ fontWeight: 300, fontSize: 14, color: 'black' }}>{values.collectName} {values.collectSurname}</p>
                        </div>
                    </Col>

                    <Col xs="12" style={{ marginBottom: '-10px' }}>
                        <div className="d-flex justify-content-between">
                            <p style={{ fontWeight: 300, fontSize: 14, color: 'black' }}>ภาระหนี้รวมทั้งหมด</p>
                            <p style={{ fontWeight: 300, fontSize: 14, color: 'black' }}>{values.totalDebt}</p>
                        </div>
                    </Col>
                    <Col xs="12" style={{ marginBottom: '-10px' }}>
                        <div className="d-flex justify-content-between">
                            <p style={{ fontWeight: 300, fontSize: 14, color: 'black' }}>ผ่อนชำระต่อเดือน</p>
                            <p style={{ fontWeight: 300, fontSize: 14, color: 'black' }}>{values.monthlyInstallments}</p>
                        </div>
                    </Col>
                    <Col xs="12" style={{ marginBottom: '-10px' }}>
                        <div className="d-flex justify-content-between">
                            <p style={{ fontWeight: 300, fontSize: 14, color: 'black' }}>จำนวนเงินที่ขอกู้</p>
                            <p style={{ fontWeight: 300, fontSize: 14, color: 'black' }}>{values.amountReq}</p>
                        </div>
                    </Col>
                    <Col xs="12" style={{ marginBottom: '-10px' }}>
                        <div className="d-flex justify-content-between">
                            <p style={{ fontWeight: 300, fontSize: 14, color: 'black' }}>ยอดที่ต้องการผ่อนชำระ</p>
                            <p style={{ fontWeight: 300, fontSize: 14, color: 'black' }}>{values.payAble}</p>
                        </div>
                    </Col>
                    <Col xs="12" style={{ marginBottom: '10px' }}>
                        <div className="d-flex justify-content-between">
                            <p style={{ fontWeight: 300, fontSize: 14, color: 'black' }}>วัตถุประสงค์</p>
                            <p style={{ fontWeight: 300, fontSize: 14, color: 'black' }}>{values.objective}</p>
                        </div>
                    </Col>
                    <Col xs="12" style={{ marginBottom: '-10px' }}>
                        <div className="d-flex justify-content-between">
                            <p style={{ fontWeight: 300, fontSize: 14, color: 'black' }}>รับเงินโดยธนาคาร</p>
                            <p style={{ fontWeight: 300, fontSize: 14, color: 'black' }}>{values.bank}</p>
                        </div>
                    </Col>
                    <Col xs="12" style={{ marginBottom: '-10px' }}>
                        <div className="d-flex justify-content-between">
                            <p style={{ fontWeight: 300, fontSize: 14, color: 'black' }}>เลขที่</p>
                            <p style={{ fontWeight: 300, fontSize: 14, color: 'black' }}>{values.account}</p>
                        </div>
                    </Col>
                </Row >
            </div >
            <div>
                <Row>
                    <Col xs="12">
                        <Form.Group>
                            <Form.Check className='custom-radio-dot'>
                                <Form.Check.Input type="checkbox" name="consent1" checked={Boolean(values.consent1)} disabled={values.haveData} onChange={(event) => {
                                    setValues({
                                        ...values,
                                        [event.target.name]: !values.consent1,
                                    });
                                }}
                                    style={{
                                        width: '20px',
                                        height: '20px',
                                        borderRadius: '50px'
                                    }} />
                                <Form.Check.Label style={{
                                    marginLeft: '10px',
                                }}>
                                    <p style={{ textDecoration: 'underline', fontWeight: 300 }} onClick={() => handleShowModal1()}>
                                        การคุ้มครองข้อมูลส่วนบุคคล
                                    </p>
                                </Form.Check.Label>
                            </Form.Check>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col xs="12">
                        <Form.Group>
                            <Form.Check className='custom-radio-dot'>
                                <Form.Check.Input type="checkbox" name="consent2" checked={Boolean(values.consent2)} disabled={values.haveData} onChange={(event) => {
                                    setValues({
                                        ...values,
                                        [event.target.name]: !values.consent2,
                                    });
                                }}
                                    style={{
                                        width: '20px',
                                        height: '20px',
                                        borderRadius: '50px'
                                    }} />
                                <Form.Check.Label style={{
                                    marginLeft: '10px',
                                }}>
                                    <p style={{ textDecoration: 'underline', fontWeight: 300 }} onClick={() => handleShowModal2()}>
                                        เงื่อนไขข้อกำหนดการผ่อนชำระ
                                    </p>
                                </Form.Check.Label>
                            </Form.Check>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col xs="12">
                        <Form.Group>
                            <Form.Check className='custom-radio-dot'>
                                <Form.Check.Input type="checkbox" name="consent3" checked={Boolean(values.consent3)} disabled={values.haveData} onChange={(event) => {
                                    setValues({
                                        ...values,
                                        [event.target.name]: !values.consent3,
                                    });
                                }}
                                    style={{
                                        width: '20px',
                                        height: '20px',
                                        borderRadius: '50px'
                                    }} />
                                <Form.Check.Label style={{
                                    marginLeft: '10px',
                                }}>
                                    <p style={{ textDecoration: 'underline', fontWeight: 300 }} onClick={handleShowModal3}>
                                        หนังสือสัญญาเงินกู้
                                    </p>
                                </Form.Check.Label>
                            </Form.Check>
                        </Form.Group>
                    </Col >
                </Row >
                <Row>
                    <Col xs="12">
                        <Form.Group>
                            <Form.Check className='custom-radio-dot'>
                                <Form.Check.Input type="checkbox" name="consent4" checked={Boolean(values.consent4)} disabled={values.haveData} onChange={(event) => {
                                    setValues({
                                        ...values,
                                        [event.target.name]: !values.consent4,
                                    });
                                }}
                                    style={{
                                        width: '20px',
                                        height: '20px',
                                        borderRadius: '50px'
                                    }} />
                                <Form.Check.Label style={{
                                    marginLeft: '10px',
                                }}>
                                    <p style={{ textDecoration: 'underline', fontWeight: 300 }} onClick={handleShowModal4}>
                                        ข้อมูลการยินยอมในการเปิดเผยข้อมูล
                                    </p>
                                </Form.Check.Label>
                            </Form.Check>
                        </Form.Group>
                    </Col >
                </Row >
                <Row>
                    <Col xs="12">
                        <Form.Group>
                            <Form.Check className='custom-radio-dot'>
                                <Form.Check.Input type="checkbox" name="consent5" checked={Boolean(values.consent5)} disabled={values.haveData} onChange={(event) => {
                                    setValues({
                                        ...values,
                                        [event.target.name]: !values.consent5,
                                    });
                                }}
                                    style={{
                                        width: '20px',
                                        height: '20px',
                                        borderRadius: '50px'
                                    }} />
                                <Form.Check.Label style={{
                                    marginLeft: '10px',
                                }}>
                                    <p style={{ textDecoration: 'underline', fontWeight: 300 }} onClick={handleShowModal5}>
                                        ข้อมูลการยินยอมในการเก็บรวบรวม/ใช้งาน/เปิดเผยข้อมูลส่วนบุคคล
                                    </p>
                                </Form.Check.Label>
                            </Form.Check>
                        </Form.Group>
                    </Col >
                </Row >

                <br />
                <p style={{ fontWeight: 300, fontSize: 14, textAlign: 'left' }}>
                    ข้าพเจ้ารับทราบเงื่อนไขตามสัญญาเงินกู้และเงื่อนไขการชำระสินเชื่อด้วยระบบตัดเงินอัตโนมัติแล้วจึงลงลายมือชื่อไว้เป็นหลักฐานและขอรับรองว่าข้อมูลที่นำมาใช้ประกอบ
                    การขอสินชื่อนั้นเป็นความจริง หากข้อมูล หรือเอกสารหรือคำรับรองใดๆของข้าพจ้าที่ให้ไว้แก่บริษัทฯ ไม่ถูกต้องตามเงื่อนไขที่ได้รับอนุมัติสินเชื่อ บริษัทขอสงวนสิทธิ์
                    การยกเลิกสัญญาและเรียกเก็บคืนเงินตามสัญญาเงินกู้ทั้งจำนวนทันที
                </p>

                <div className="mt-5">
                    <Row>
                        <Button
                            variant="secondary"
                            onClick={handleRegister}
                            disabled={!(
                                values.consent1 &&
                                values.consent2 &&
                                values.consent3 &&
                                values.consent4 &&
                                values.consent5
                            )}
                        >
                            ยืนยันข้อมูล
                        </Button>
                        <Button variant="danger" style={{ borderRadius: '10px', marginTop: '20px', display: values.haveData ? '' : 'none' }} onClick={() => window.history.back()}>ยกเลิกแก้ไขข้อมูล</Button>
                    </Row>
                </div>
            </div >

            <Consent1 show={showModal1} handleClose={handleCloseModal1} />
            <Consent2 show={showModal2} handleClose={handleCloseModal2} />
            <Consent3 show={showModal3} handleClose={handleCloseModal3} />
            <Consent4 show={showModal4} handleClose={handleCloseModal4} />
            <Consent5 show={showModal5} handleClose={handleCloseModal5} />

        </div >
    )
}

export default FormSummary
