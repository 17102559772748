import React from 'react'
import { Modal } from 'react-bootstrap'
import CloseButton from 'react-bootstrap/CloseButton';
import LogoPico from './../../assets/images/Logo-Pico-เงินด่วน.png';
// import LogoCashkrub from './../../assets/images/CASH-KRUB-LOGO-02-white.png';

const Model = ({ show, handleClose }) => {
    // const brand = localStorage.getItem("brand");
    // const logoSource = brand === 'CASHKRUB' ? LogoCashkrub : LogoMscw;

    return (
        <div>
            <Modal show={show} onHide={handleClose}>
                <Modal.Body>
                    <div className="right">
                        <CloseButton style={{ backgroundColor: '#fff', padding: '10px', marginBottom: '20px', fontSize: '12px' }} onClick={handleClose} />
                    </div>
                    <div className="center mb-4">
                        <p style={{ fontSize: '16px', textDecoration: 'underline' }}>
                            ข้อมูลการยินยอมในการเปิดเผยข้อมูล
                        </p>
                        <img src={LogoPico} className="logo center" alt="" />
                    </div>
                    <p style={{ marginBottom: '5rem', fontSize: '12px' }}>
                        ข้าพเจ้าตกลงยินยอมให้ บริษัท ข้อมูลเครดิตแห่งชาติ จำกัด (บริษัท
                        เปิดเผยหรือให้ข้อมูลของข้าพเจ้าแก่ บริษัท พิโก โซลูชั่นส์(ไทยแลนด์) จำกัด
                        ซึ่งเป็นสมาชิกหรือผู้ใช้บริการของบริษัท
                        เพื่อประโยชน์ในการวิเคราะห์สินเชื่อการออกบัตรเครดิต ตามคำขอสินเชื่อ / ขอออกบัตรเครดิต
                        ของข้าพเจ้าที่ให้ไว้กับธนาคาร / บริษัทดังกล่าวข้างต้นรวมทั้งเพื่อประโยชน์ในการทบทวบสินเชื่อต่ออายุสัญญาสินเชื่อ / บัตรเครดิต
                        การบริหารและป้องกันความเสี่ยงตามข้อกำหนดของธนาคารแห่งประเทศไทย
                        และให้ถือว่าคู่ฉบับและบรรดาสำเนาภาพถ่ายข้อมูลอิเล็กทรอนิกส์หรือโทรสารที่ทำสำเนาขึ้นจาก
                        หนังสือให้ความยินยอมฉบับนี้โดยการถ่ายสำเนาถ่ายภาพหรือบันทึกไว้ไม่ว่าในรูปแบบใดๆ
                        เป็นหลักฐานในการให้ความยินยอมของข้าพเจ้าเช่นเดียวกัน<br />
                        <br />
                        หมายเหตุ:
                        ข้อมูลที่บริษัทเปิดเผยให้แก่สมาชิกหรือผู้ใช้บริการเป็นองค์ประกอบหนึ่งในการพิจารณาสินเชื่อของสถาบันการเงิน
                        แต่การเปิดเผยข้อมูลดังกล่าวเป็นสิทธิของเจ้าของข้อมูลที่จะให้ความยินยอมหรือไม่ก็ได้<br />
                    </p>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default Model