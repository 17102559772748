import React from 'react'
import { Modal } from 'react-bootstrap'
import CloseButton from 'react-bootstrap/CloseButton';
import LogoPico from './../../assets/images/Logo-Pico-เงินด่วน.png';
// import LogoCashkrub from './../../assets/images/CASH-KRUB-LOGO-02-white.png';

const Model = ({ show, handleClose }) => {
    // const brand = localStorage.getItem("brand");
    // const logoSource = brand === 'CASHKRUB' ? LogoCashkrub : LogoMscw;

    return (
        <div>
            <Modal show={show} onHide={handleClose}>
                <Modal.Body>
                    <div className="right">
                        <CloseButton style={{ backgroundColor: '#fff', padding: '10px', marginBottom: '20px', fontSize: '12px' }} onClick={handleClose} />
                    </div>
                    <div className="center mb-4">
                        <p style={{ fontWeight: '16px', textDecoration: 'underline' }}>ข้อมูลการยินยอมในการเก็บรวบรวม/ใช้งาน/เปิดเผยข้อมูลส่วนบุคคล</p>
                        <img src={LogoPico} className="logo center" alt="LogoImg" />
                    </div>
                    <p style={{ marginBottom: '5rem', fontSize: '12px' }}>
                        &emsp;&emsp;หนังสือให้ความยินยอมในการประมวลผลข้อมูลส่วนบุคคล (&quot;หนังสือๆ นี้
                        จัดทำขึ้นโดย <b>บริษัท พิโก โซลูชั่นส์(ไทยแลนด์) จำกัด (&quot;บริษัทฯ&quot;) </b>
                        เพื่อให้การเก็บรวบรวม ใช้หรือเปิดเผย ประมวลผล)
                        ข้อมูลส่วนบุคคลของเจ้าของข้อมูลส่วนบุคคลเป็นไปตามพระราชบัญญัติคุ้มครอง
                        ข้อมูลส่วนบุคคล พ.ศ.2562 โดยบริษัทฯ
                        ประสงค์จะได้รับความยินยอมส่วนบุคคลไปดำเนินการประมวลผล
                        หากท่านยินยอมให้ข้อมูลส่วนบุคคลใดของท่านกับทางบริษัทฯ <b>ในฐานะ &quot;ลูกค้า&quot; </b>
                        ยินยอมให้<b>บริษัท พิโก โซลูชั่นส์(ไทยแลนด์) จำกัด </b>ดำเนินการ จัดเก็บ รวมรวม
                        ใช้หรือเปิดเผยข้อมูลส่วนบุคคลท่านตามวัตถุประสงค์หรือเพื่อใช้ในกิจกรรมดังต่อไปนี้<br />
                        <br />
                        <b>ส่วนที่ 1 </b> ให้บริษัทฯ ทำการใช้ข้อมูลส่วนบุคคล ได้แก่ เบอร์โทรศัพท์ อีเมล เป็นต้น <br />
                        วัตถุประสงค์: เพื่อวัตถุประสงค์ทางการตลาด ได้แก่ การนำเสนอผลิตภัณฑ์ หรือบริการใหม่ การส่งข้อความเที่ยวกับโปรโมชั่นการจัดรายการส่งเสริมการขาย โฆษณา ประชาสัมพันธ์ข่าวสาร เกี่ยวกับสินค้า และสิทธิพิเศษต่างๆ ให้กับท่าน <br />
                        <br />
                        กรณีที่ท่านไม่ให้ยินยอม ท่านอาจจะไม่ได้รับข้อมูลข่าวสาร หรือข้อมูลผลิตภัณฑ์
                        หรือบริการใหม่ที่ท่านอาจสนใจจากทางบริษัทฯ<br />

                        <br />
                        <b>ข้อมูลส่วนบุคคลที่มีความอ่อนไหว (Sensitive Data)</b>
                        <br />

                        <br />
                        <b>ส่วนที่ 2 ให้บริษัทฯ ทำการเก็บรวบรวมและใช้ข้อมูลส่วนบุคคลที่มีความอ่อนไหวของท่าน ได้แก่ ศาสนา เชื้อชาติ </b>
                        ที่ปรากฎในสำเนาบัตรประชาชน สำเนาทะเบียนบ้านและเอกสารประกอบการสมัครสินเชื่อ เป็นต้น <br />
                        วัตถุประสงค์: เพื่อให้บริษัทสามารถใช้เอกสารประกอบการสมัครของท่านในขั้นตอนการพิจารณา (กรณีที่ท่านไม่ให้ยินยอม ขอให้ท่านดำเนินการขีดฆ่า หรือลบข้อมูลดังกล่าวออกจากเอกสารก่อนทำการส่งให้กับบริษัท)
                        <br />
                        *ทั้งนี้ หากท่านไม่ได้ดำเนินการขีดฆ่าข้อมูลฯ
                        ดังกล่าวออกจากเอกสารก่อนส่งมาให้กับบริษัท
                        บริษัทจะถือว่าท่านได้อนุญาตให้บริษัทสามารถดำเนินการตามขั้นตอนการปกปิด
                        หรือทำลายข้อมูล โดยการขีดฆ่า หรือลบข้อมูลฯ ดังกล่าวให้กับท่านได้ทันที
                        <br />
                        <br />
                        <b>หมายเหตุ: </b>ท่านสามารถเลือกให้ความยินยอมทั้งหมด บางส่วนหรือไม่ให้ความยินยอม<br/>
                        &emsp;&emsp;ทั้งนี้ ท่านสามารถดูรายละเอียดเพิ่มเติมเกี่ยวกับวัตถุประสงค์ ระยะเวลาในการเก็บข้อมูลส่วนบุคคล และสิทธิของเจ้าของข้อมูลส่วนบุคคลได้ที่ "ประกาศเที่ยวกับการคุ้มครอง ข้อมูลส่วน
                        บุคคล (Privacy Notice) สำหรับลูกค้า" ตามเอกสารแนบท้ายหนังสือขอความยินยอมฉบับนี้หรือตามประกาศความเป็นส่วนตัวที่บริษัทฯ ประชาสัมพันธ์ไว้บนเว็บไซต์<br/>
                        &emsp;&emsp;ข้าพเจ้าได้อ่านและรับทราบประกาศความเป็นส่วนตัวของบริษัทแล้ว และข้าพเจ้ารับทราบว่า ข้าพเจ้ามีสิทธิถอนความยินยอมในการเก็บรวบรวม ใช้ และ/หรือ เปิดเผยข้อมูลส่วนบุคคล
                        ของข้าพเจ้า เพื่อวัตถุประสงค์ตามที่ระบุไว้ในหนังสือฉบับนี้ได้ตลอดเวลา โดยแจ้งให้กับบริษัททราบเป็นลายลักษณ์อักษรถึงการถอนความยินยอมเช่นว่านั้นได้<br/>
                        ท่านสามารถขอใช้สิทธิในการถอน
                        ความยินยอมได้ผ่านช่องทางที่บริษัทกำหนดด้านล่างนี้
                        <br />
                        <br />
                        <b>ส่วนที่ 3 ประกาศความเป็นส่วนตัว ( Privacy Notice)</b>
                        <br />
                        ข้าพเจ้าได้อ่านและเข้าใจประกาศความเป็นส่วนตัว (Privacy Notice) ของบริษัทซึ่งมีรายละเอียดเกี่ยวกับการคุ้มครองข้อมูลส่วนบุคคลของข้าพเจ้าแล้ว
                        ท่านสามารถอ่านประกาศความเป็นส่วนตัวของบริษัทฯ ซึ่งเผยแพร่บนเว็บไซต์ https://pico-thai.com/privacy-policy/
                    </p>
                    <div className="right">
                        <CloseButton style={{ backgroundColor: '#fff', padding: '10px', marginBottom: '30px' }} onClick={handleClose} />
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default Model