/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */

import { useEffect, useState, useRef } from "react";
// import { Typeahead } from 'react-bootstrap-typeahead';

import { Col, Row, Carousel, Button, ProgressBar, Modal, Form, Nav, Navbar, Container, NavItem, NavLink } from 'react-bootstrap';
import { Link, useNavigate } from "react-router-dom";
// import { useSelector, useDispatch } from 'react-redux';
// import { useLiff } from 'react-liff';
// import InputGroup from 'react-bootstrap/InputGroup';
// import { padLeadingZeros } from '../utils';
// import provincesData from '../datas/provinces.json';

import LogoPico from '../../assets/images/Logo-Pico-เงินด่วน.png'
// import * as userHttpServices from '../services/digital-app-services';
// import { Formik } from 'formik';
// import * as Yup from "yup";

function Home() {
    let navigate = useNavigate();
    const [pdpa, setPdpa] = useState(false)

    // orientation
    const [orientation, setOrientation] = useState(window.orientation);
    useEffect(() => {
        const handleOrientationChange = () => {
            const newOrientation = window.orientation || window.screen.orientation.angle;
            setOrientation(newOrientation);
        };

        window.addEventListener("orientationchange", handleOrientationChange);
        return () => {
            window.removeEventListener("orientationchange", handleOrientationChange);
        };
    }, []);

    useEffect(() => {
    }, []);

    const handleConsent = () => {
        navigate('/direct_debit/end')
    }

    if (false) {
        return
    } else {
        return (
            <>
                <div className="mobile">
                    <div className="p-3">
                        <div className="center" >
                            <p style={{ textDecoration: "underline", fontSize: '16px' }}>ข้อมูลการยินยอมในการเก็บรวบรวม/ใช้งาน/เปิดเผยข้อมูลส่วนบุคคล</p>
                            <img src={LogoPico} className="logo center" alt="" />
                        </div>
                        <br />
                        <p style={{ marginBottom: '5rem', fontSize: '12px' }}>
                            &emsp;&emsp;หนังสือให้ความยินยอมในการประมวลผลข้อมูลส่วนบุคคล ("หนังสือๆ นี้ จัดทำขึ้นโดย บริษัท พิโก โซลูชั่นส์(ไทยแลนด์) จำกัด ("บริษัทฯ") เพื่อให้การเก็บรวบรวม ใช้หรือเปิดเพย ประมวลผล) ข้อมูลส่วนบุคคลของเจ้าของข้อมูลส่นบุคคลเป็นไปตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ.2562 โดยบริษัทฯ ประสงค์จะได้รับความยินยอมส่วนบุคคลไปดำเนินการประมวลผล หากท่านยินยอมให้ข้อมูลส่วนบุคคลใดของท่านกับทางบริษัทฯ ในฐานะ "ลูกค้า" ยินยอมให้ บริษัท พิโก โซลูชั่นส์(ไทยแลนด์) จำกัด ดำเนินการ จัดเก็บ รวมรวม ใช้หรือเปิดเผยข้อมูลส่วนบุคคลท่านตามวัตถุประสงค์หรือเพื่อใช้ในกิจกรรมดังต่อไปนี้<br />
                            <br />
                            <b>ส่วนที่ 1 </b> ให้บริษัทฯ ทำการใช้ข้อมูลส่วนบุคคล ได้แก่ เบอร์โทรศัพท์ อีเมล เป็นต้น <br />
                            วัดถุประสงค์: เพื่อวัตถุประสงค์ทางการตลาด ได้แก่ การนำเสนอผลิตภัณฑ์ หรือบริการใหม่ การส่งข้อความเที่ยวกับโปรโมชั่นการจัดรายการส่งเสริมการขาย โฆษณา ประชาสัมพันธ์ข่าวสาร เกี่ยวกับสินค้า และสิทธิพิเศษต่างๆ ให้กับท่าน <br />
                            <br />
                            กรณีที่ท่านไม่ให้ยินยอมท่านอาจจะไม่ได้รับข้อมูลข่าวสาร หรือข้อมูลผลิตภัณฑ์ หรือบริการใหม่ที่ท่านอาจสนใจจากทางมริษัทฯข้อมูลส่วนบุคคลที่มีความอ่อนไหว (Sensitive Data)<br />
                            <br />
                            <b>ส่วนที่ 2 </b> ที่ปรากฎในสำเนาบัตรประชาชน สำเนาทะเบียนบ้านและเอกสารประกอบการสมัครสินเชื่อ เป็นต้น <br />
                            วัตถุประสงค์: เพื่อให้บริษัทสามารกใช้เอกสารประกอบการสมัครของท่านในขั้นตอนการพิจารณา(กรณีที่ท่านไม่ให้ยินยอม ขอให้ท่านดำเนินการขีดฆ่า หรือลบข้อมูลดังกล่าวออกจากเอกสารก่อนทำการส่งให้กับบริษัท)
                            <br />
                            <br />
                            <b>หมายเหตุ: </b>ท่านสามารถลือกให้ความยินยอมทังหมด บางส่วน หรือไม่ให้ความยินยอม
                            ทั้งนี้ ท่านสามารถดูรายละเอียดเพิ่มเติมเกี่ยวกับวัตถุประสงค์ ระยะเวลาในการเก็บข้อมูลส่วนบุคคล และสิทธิของเจ้าของข้อมูลส่วนบุคคลได้ที่ "ประกาศเที่ยวกับการคุ้มครอง ข้อมูลส่วน
                            บุคคล (Privacy Notice) สำรับลูกค้า" ตามเอกสารแนบท้ายหนังสือขอความยินยอมฉบับนี้หรือตามประกาศความเป็นส่วนตัวที่บริษัทฯ ประชาสัมพันธ์ไว้บนเว็บไซต์
                            ข้าพเจ้าได้อ่านและรับทราบประกาศความเป็นส่วนตัวของบริษัทแล้ว และข้าพเจ้ารับทราบว่า ข้าพเจ้ามีสิทธิถอนความยินยอมในการเก็บรวบรวม ใช้ และ/หรือ เปิดเผยข้อมูลส่วนบุคคล
                            ของข้าพเจ้า เพื่อวัตถุประสงค์ตามที่ระบุไว้ในหนังสือฉบับนี้ได้ตลอดเวลา โดยแจ้งให้กับบริษัททราบเป็นลายลักษณ์อักษรถึงการถอนความยินยอมเช่นว่านั้นได้ ท่านสามารถขอใช้สิทธิในการถอน
                            ความยินยอมได้ผ่านช่องทางที่บริษัทกำหนดด้านล่างนี้
                            <br />
                            <br />
                            <b>ส่วนที่ 3 ประกาศความเป็นส่วนตัว ( Privacy Notice)</b>
                            <br />
                            ข้าพเจ้าได้อ่านและเข้าใจประกาศความเป็นส่วนตัว (Privacy Notice) ของบริษัทซึ่งมีรายละเอียดเกี่ยวกับการคุ้มครองข้อมูลส่วนบุคคลของข้าพเจ้าแล้ว
                            ท่านสามารถอ่านประกาศความเป็นส่วนตัวของบริษัทฯ ซึ่งเผยแพร่บนเว็บไซต์ https://pico-thai.com/privacy-policy/
                        </p>

                        <div className="m-5"></div>
                        <Form>
                            <Form.Check>
                                <Form.Check.Input type="checkbox" onChange={() => setPdpa(!pdpa)} />
                                <Form.Check.Label>
                                    <p style={{ fontSize: '14px' }}>
                                        โดยการเลือก "สร้างบัญชี" แสดงว่าคุณยืนยัน
                                        ที่คุณได้อ่านและยอมรับของ Direct debit
                                        ข้อกำหนดการใช้งานและนโยบายความเป็นส่วนตัว
                                    </p>
                                </Form.Check.Label>
                            </Form.Check>
                        </Form>
                        <br />
                        <Row>
                            <Button variant="secondary" type="submit" disabled={!pdpa} onClick={() => handleConsent()}>สร้างบัญชี</Button>
                        </Row>
                    </div>
                </div>
            </>
        )
    }
}

export default Home;