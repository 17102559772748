import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import LogoPico from './../../assets/images/Logo-Pico-เงินด่วน.png';
// import LogoCashkrub from './../../assets/images/CASH-KRUB-LOGO-02-white.png';
import * as userHttpServices from '../../services/digital-app-services';

const OTP = ({ setPage, phone, setPhone }) => {
    const [isValid, setIsValid] = useState(true);

    const handleChange = (e) => {
        const phoneNumber = e.target.value;
        setPhone(phoneNumber);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const phoneRegex = /^\d{10}$/;

        // Test if the input matches the regex
        setIsValid(phoneRegex.test(phone));

        if (phoneRegex.test(phone)) {
            // Your form submission logic here
            const data = {
                phone_number: phone
            }
            userHttpServices.requestOTP(data)
                .then((res) => {
                    console.log('res', res)
                    setPage((prev) => prev + 1)
                })
                .catch((err) => alert('[online L128] Error have had been found'))
        }
    };

    return (
        <div className="center" style={{ maxWidth: '30rem' }}>
            <div className="center" style={{ paddingTop: '50px' }}>
                <div style={{ paddingLeft: '20px' }}>
                    <img src={LogoPico} alt="" style={{ width: '250px', margin: '30px' }} />
                    {/* <img src={LogoCashkrub} alt="" style={{ width: '140px', marginLeft: '20px',marginTop:'20px' }} /> */}
                </div>
            </div>

            <div style={{ padding: '30px' }}>
                <p className="p-2" style={{ fontWeight: 400, fontSize: 18 }}>กรอกเบอร์มือถือ</p>

                <Form onSubmit={handleSubmit}>
                    <Form.Group>
                        <Form.Control
                            className="center"
                            size="md"
                            onChange={handleChange}
                            value={phone}
                            id="phone"
                            type="tel"
                            isInvalid={!isValid}
                            style={{
                                borderRadius: '20px',
                                fontSize: '18px',
                                width: '300px',
                            }}
                        />
                        <Form.Control.Feedback type="invalid">
                            เบอร์มือถือต้องเป็นตัวเลข 10 หลักที่ถูกต้อง
                        </Form.Control.Feedback>
                    </Form.Group>
                    <br />
                    <Button
                        variant="primary"
                        type="submit"
                        style={{
                            borderRadius: '30px',
                            width: '120px',
                            backgroundColor: phone.length === 10 ? '#02a24f' : '',
                            border: 'none',
                        }}
                    >
                        เรียบร้อย
                    </Button>
                </Form>
            </div>
        </div>
    );
};

export default OTP;
