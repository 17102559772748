export function padLeadingZeros(num, size) {
    var s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
}

export function thaiFullDateFormatDayJs(dayjs) {
    const thaiDayName = ["อาทิตย์", "จันทร์", "อังคาร", "พุธ", "พฤหัสบดี", "ศุกร์", "เสาร์"]
    const thaiMonthName = ["มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน", "พฤษภาคม", "มิถุนายน", "กรกฎาคม", "สิงหาคม", "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม"]

    return "วัน" + thaiDayName[dayjs.day()] + "ที่ " + dayjs.date() + " " + thaiMonthName[dayjs.month()] + " " + (dayjs.year() + 543)
}
