import React from 'react'

const Stepper = ({ step }) => {

    return (
        <div>
            <div style={{ margin: '2rem 0 3.5rem 0' }}>
                <div className='center' style={{ display: 'flex' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <div
                            style={{
                                width: '30px',
                                height: '30px',
                                backgroundColor: 'rgba(36, 62, 123, 1)',
                                borderRadius: '50%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                color: '#fff'
                            }}
                        >
                            1
                        </div>
                        <div className='content-2' style={{ position: 'absolute', marginTop: '2.2rem', color: 'rgba(36, 62, 123, 1)' }}>ยื่นเอกสาร</div>
                    </div>
                    <hr
                        style={{
                            width: '80px',
                            border: '1px solid rgba(36, 62, 123, 1)'
                        }}
                    />
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <div
                            style={{
                                width: '30px',
                                height: '30px',
                                border: '3px solid rgba(36, 62, 123, 1)',
                                backgroundColor: step === 2 ? 'rgba(36, 62, 123, 1)' : null,
                                borderRadius: '50%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                color: '#fff'
                            }}
                        >
                            {step === 2 ? 2 : null}
                        </div>
                        <div className='content-2' style={{ position: 'absolute', marginTop: '2.2rem', color: 'rgba(36, 62, 123, 1)' }}>ข้อมูลเครดิต</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Stepper
